import { Grid, TableSortLabel } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import ButtonExportChart from "../../ButtonExportChart";
import Chart from "../../Chart";

// import "./style.css";
import { useTheme } from "@emotion/react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Modal,
	Slide,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ptBR } from "@mui/material/locale";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import HelpButtonInput from "../../../components/HelpButtonInput";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";
import InputData from "../../InputData";
import styles from "./RelatorioOcorrencia.module.css";
import { CropSeasonService } from "../../../service/crop_season_service";
import { PropertyService } from "../../../service/property_service";
import { FarmService } from "../../../service/farm_service";
import { ReportService } from "../../../service/report_service";
const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);
const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	m: 0,
	p: 0,
};
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	transform: "translate(-50%, -50%)",
};
function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};
const headCells = [
	{
		id: "nome",
		label:"Nome"
	},
	{
		id: "data_plantio",
		label: "Data Plantio"
	}
];
function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort } =
	props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function RelatoriosOcorrencia(props) {
	const [allSafras, setAllSafras] = useState([]);
	const [selectedSafra, setSelectedSafra] = useState(-1);
	const [allCultivos, setAllCultivos] = useState([]);
	const [selectedCultivo, setSelectedCultivo] = useState(-1);
	const [allTalhoes, setAllTalhoes] = useState([]);
	const [selectedTalhao, setSelectedTalhao] = useState(-1);
	const [allSubdivisoes, setAllSubdivisoes] = useState([]);
	const [selectedSubdivisao, setSelectedSubdivisao] = useState(-1);
	const [page, setPage] = React.useState(0); //paginação
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //paginação
	const [openSafraDialog, setOpenSafraDialog] = useState(false);
	const [openSafra, setOpenSafra] = useState(false);
	const [idRowSafra, setIdRowSafra] = useState(null);
	const [idRowTalhao, setIdRowTalhao] = useState(null);
	const [idRowCultura, setIdRowCultura] = useState(null);
	const [idRowSub, setIdRowSub] = useState(null);
	const [disable, setDisable] = useState(true);
	const [personalizedStartDate, setPersonalizedStartDate] = useState(null);
	const [personalizedFinalDate, setPersonalizedFinalDate] = useState(null);
	const [disableSelectSafra, setDisableSelectSafra] = useState(false);
	const [selectedDateInicio, setSelectedDateInicio] = useState("");
	const [selectedDateFim, setSelectedDateFim] = useState("");
	const [dataTalhao, setDataTalhao] = useState([
		["Talhao", "Por Talhao"],
		["Talhão", 0]
	]);
	const [dataParcela, setDataParcela] = useState([
		["Parcela", "Por Parcela"],
		["Parcela", 0],
	]);
	const [dataSafra, setDataSafra] = useState([
		["Safra", "Por Safra"],
		["Safra", 0],
	]);
	const [dataType, setDataType] = useState([
		["Tipo", "Por Tipo"],
		["Tipo", 0],
	]);
	
	const keysForActiveSafra = headCells; //array de chaves
	const [selected, setSelected] = useState([]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("cuture");

	const cropSeasonService = new CropSeasonService();
	const propertyService = new PropertyService();
	const farmService = new FarmService();
	const reportService = new ReportService();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = dataAllSafras.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	
	const handleDateChangeInicio = (date) => {
		setSelectedDateInicio(new Date(date).toLocaleDateString("pt-BR"));
		setDataInico(new Date(date).toLocaleDateString("pt-BR"));
	};
	const handleDateChangeFim = (date) => {
		setSelectedDateFim(new Date(date).toLocaleDateString("pt-BR"));
		setDataFim(new Date(date).toLocaleDateString("pt-BR"));
	};

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");

		const plots = await propertyService.getPlots(propertyId);
		setAllTalhoes(plots);

		let cropSeasons = await cropSeasonService.get(propertyId, {limit: 100000});
		cropSeasons = cropSeasons.cropsSeason;
		setAllSafras(await getSafras(cropSeasons));
		setSelectedSafra(-1);

		const farms = await farmService.get({limit: 100000},propertyId);
		setAllCultivos(await getCultivos(farms.farms));

		const subdivisions = await propertyService.getSubdivisions(propertyId);
		setAllSubdivisoes(await getSubdivisoes(subdivisions));
	};

	useEffect(() => {
		const dataInicio = getFormatDate(getStartDate());
		const dataFinal = getFormatDate(getFinalDate());
		if(String(selectedTalhao) !== "-1")
			incidentInPlot(selectedTalhao, dataInicio, dataFinal);
		if(String(selectedSubdivisao) !== "-1")
			incidentInSubdivision(selectedSubdivisao, dataInicio, dataFinal);
		if(String(selectedSafra) !== "-1")
			incidentByCropseason(selectedSafra, dataInicio, dataFinal);
		incidentByType(dataInicio, dataFinal);
	}, [
		personalizedStartDate,
		personalizedFinalDate,
		selectedSafra,
		selectedTalhao,
		selectedSubdivisao,
	]);
	
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};

	const incidentInPlot = async (plotId, startDate, finalDate) => {
		const params = {
			plot: plotId,
			startDate: startDate,
			finalDate: finalDate
		};

		const incidentData = await reportService.getIncidentByPlotGraphicData(params);
		const newData = [dataTalhao[0]];
		for (const key in incidentData) newData.push([key, incidentData[key]]);
		if(newData.length > 1){
			setDataTalhao(newData);
		}else {
			setDataTalhao([
				["Talhao", "Por Talhao"],
				["Talhão", 0]
			]);
		}
	};

	const incidentInSubdivision = async (subdivisionId, startDate, finalDate) => {
		const params = {
			subdivision: subdivisionId,
			startDate: startDate,
			finalDate: finalDate
		};

		const incidentData = await reportService.getIncidentBySubdivisionGraphicData(params);
		const newData = [dataParcela[0]];
		for (const key in incidentData) newData.push([key, incidentData[key]]);

		if(newData.length > 1){
			setDataParcela(newData);
		}else {
			setDataParcela([
				["Parcela", "Por Parcela"],
				["Parcela", 0],
			]);
		}
	};

	const incidentByCropseason = async (cropseasonId, startDate, finalDate) => {
		const params = {
			cropSeason: cropseasonId,
			startDate: startDate,
			finalDate: finalDate
		};

		const incidentData = await reportService.getIncidentByCropSeasonGraphicData(params);
		const newData = [dataSafra[0]];
		for (const key in incidentData) newData.push([key, incidentData[key]]);

		if(newData.length > 1){
			setDataSafra(newData);
		}else {
			setDataSafra([
				["Safra", "Por Safra"],
				["Safra", 0],
			]);
		}
	};

	const incidentByType = async (startDate, finalDate) => {
		const params = {
			startDate: startDate,
			finalDate: finalDate
		};

		const incidentData = await reportService.getIncidentByCropSeasonGraphicData(params);
		const newData = [dataType[0]];
		for (const key in incidentData) newData.push([key, incidentData[key]]);

		if(newData.length > 1){
			setDataType(newData);
		}else {
			setDataType([
				["Tipo", "Por Tipo"],
				["Tipo", 0],
			]);
		}
	};

	const getCultivos = async (cultivosInProperty) => {
		const cultivos = [];
		for (let i = 0; i < cultivosInProperty.length; i++) {
			const cultivoInProperty = cultivosInProperty[i];
			const name = getCultivoName(cultivoInProperty);
			cultivos.push({
				id: cultivoInProperty.id,
				item: cultivoInProperty,
				name: name,
			});
		}
		return cultivos;
	};

	const getSubdivisoes = async (subdivisoesInProperty) => {
		const subdivisoes = [];
		for (let i = 0; i < subdivisoesInProperty.length; i++) {
			const subdivisaoInProperty = subdivisoesInProperty[i];
			const name = getSubdivisaoName(subdivisaoInProperty);
			subdivisoes.push({
				id: subdivisaoInProperty.id,
				item: subdivisaoInProperty,
				name: name,
			});
		}
		return subdivisoes;
	};

	const getCultivoName = (cultivoInProperty) => {
		const cultivo = cultivoInProperty.cultivo;

		return captalizeFirstLetterEacheWord(cultivo.cultura + " " + (cultivo.variedade ?? ""));
	};

	const getSubdivisaoName = (subdivisaoInProperty) => {
		return (
			(captalizeFirstLetterEacheWord(subdivisaoInProperty.categoriaSubdivisao)) +
			" " +
			subdivisaoInProperty.complemento
		);
	};
	
	const getSafras = async (cropSeasons) => {
		const safras = [];
		for (let j = 0; j < cropSeasons.length; j++) {
			const cropSeason = cropSeasons[j];
			const farm = cropSeason.propriedadeCultivo;
			const subDiv = cropSeason.subdivisao;
			const cultivation = farm.cultivo;
			const catSub = subDiv?.categoriaSubdivisao;
			const plot = cropSeason.talhao;
			safras.push({
				talhaoId: plot.id,
				id: cropSeason.id,
				idCultivo: farm.id,
				idTalhao: plot.id,
				idsub: subDiv !== null ? subDiv.id : -1,
				data_plantio: new Date(cropSeason.dataPlantio).toLocaleDateString(
					"pt-BR"
				),
				safra: cropSeason,
				name: `Talhão ${plot.nome}${
					subDiv ? captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
				} - ${captalizeFirstLetterEacheWord(cultivation.cultura)} ${captalizeFirstLetterEacheWord(cultivation.variedade ?? "")}`,
			});
		}
		return safras;
	};

	const handleOpenSafra = () => {
		setOpenSafra(true); //abre o modal
	};
	const handleCloseSafra = () => {
		setOpenSafra(false); //fecha o modal
	};

	let dataAllSafras = [];

	dataAllSafras.push({
		id: 0,
		nome: "TODOS",
		data_plantio: "-",
		index: 0,
		talhaoId: -1,
		subId: -1,
		culturaId: -1,
	});

	allSafras.map((item, index) => {
		return dataAllSafras.push({
			id: item.id,
			nome: item.name,
			data_plantio: new Date(item.safra.data_plantio).toLocaleDateString("pt-BR"),
			index: index,
			talhaoId: item.talhaoId,
			subId: item?.idsub,
			culturaId: item.idCultivo,
		});
	});
	const handleChangePage = (event, newPage) => {
		setPage(newPage); //paginação
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //paginação
		setPage(0);
	};
	const handleOpenSafraDialog = (e) => {
		if (e.target.id !== undefined) {
			let eSplie = e.target.id.toString().split("&");
			let idrow = eSplie[0];
			let talhaoId = eSplie[1];
			let culturaId = eSplie[2];
			let subId = eSplie[3];
			setIdRowSafra(idrow);
			setIdRowTalhao(talhaoId);
			setIdRowCultura(culturaId);
			setIdRowSub(subId);
		} //seta o id do elemento clicado para abrir o dialog

		setOpenSafraDialog(true); //abre o modal
	};

	const handleCloseSafraDialog = () => {
		setOpenSafraDialog(false); //fecha o modal
	};

	const handleCloseAddSafra = () => {
		setSafra(idRowSafra);
		setSelectedTalhao(idRowTalhao);
		setSelectedSubdivisao(idRowSub);
		setSelectedCultivo(idRowCultura);
		setOpenSafra(false); //fecha o modal
		setOpenSafraDialog(false);
	};

	const getStartDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		return (
			personalizedStartDate ??
			`${currentDate.getDate().toString().padStart(2, "0")}/${currentMonth
				.toString()
				.padStart(2, "0")}/${currentDate.getFullYear()}`
		);
	};

	const getFinalDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		return (
			personalizedFinalDate ??
			`${currentDate.getDate().toString().padStart(2, "0")}/${currentMonth
				.toString()
				.padStart(2, "0")}/${currentDate.getFullYear()}`
		);
	};
	function setSafra(safraId) {
		setSelectedSafra(safraId);
		if (
			safraId !== 0 &&
			safraId !== -1 &&
			selectedDateInicio !== "" &&
			selectedDateFim !== ""
		)
			setDisable(false);
		else setDisable(true);
	}
	function setDataInico(e) {
		if (e !== "" && selectedDateFim !== "")
			setDisable(false);
		else setDisable(true);

		setPersonalizedStartDate(e);
	}
	function setDataFim(e) {
		if (
			selectedDateInicio !== "" &&
			e !== ""
		)
			setDisable(false);
		else setDisable(true);

		setPersonalizedFinalDate(e);
	}
	function setAllSafrasCheckBox() {
		if (
			!disableSelectSafra &&
			selectedDateInicio !== "" &&
			selectedDateFim !== ""
		) {
			setSafra(0);
			setDisable(false);
		} else {
			setSafra(-1);
			setDisable(true);
		}
		setDisableSelectSafra(!disableSelectSafra);
	}

	function filtroPdf() {
		if (selectedSafra === -1) {
			return `/pdf/?pag=Ocorrencias&index=${
				props.index
			}&safraId=${0}&talhaoId=${selectedTalhao}&parcelaId=${selectedSubdivisao}&culturaId=${selectedCultivo}&startDate=${getStartDate()}&finalDate=${getFinalDate()}`;
		} else if (
			selectedSafra === 0 &&
			((selectedTalhao !== -1 && selectedTalhao !== 0) ||
				(selectedSubdivisao !== -1 && selectedSubdivisao !== 0))
		) {
			return `/pdf/?pag=Ocorrencias&index=${
				props.index
			}&safraId=${selectedSafra}&talhaoId=${selectedTalhao}&parcelaId=${selectedSubdivisao}&startDate=${getStartDate()}&finalDate=${getFinalDate()}`;
		} else if (selectedSafra === 0) {
			return `/pdf/?pag=Ocorrencias&index=${
				props.index
			}&safraId=${0}&startDate=${getStartDate()}&finalDate=${getFinalDate()}`;
		} else {
			return `/pdf/?pag=Ocorrencias&index=${
				props.index
			}&safraId=${selectedSafra}&startDate=${getStartDate()}&finalDate=${getFinalDate()}`;
		}
	}
	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
	
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(dataAllSafras, getComparator(order, orderBy)).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[order, orderBy, page, rowsPerPage,dataAllSafras]
	);
	return (
		<Box component="main" sx={{ display: "flex", p: 0 }}>
			<Box sx={{ minWidth: "90vw", maxWidth: "95vw", p: 0 }}>
				<Box sx={{ width: "100%", p: 3, m: 0 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={{
							width: "100%",
							m: 0,
							p: 3,
							borderRadius: "8px",
							bgcolor: "white",
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 0, sm: 2, md: 3 }}
								sx={{
									p: 0,
									display: "flex",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={9}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Safra: </p>
											<Grid
												container
												rowSpacing={1}
												columnSpacing={0}
												sx={{
													p: 0,
													display: "flex",
													width: "100%",
													maxWidth: "350px",
												}}
											>
												<Grid item xs={12} sm={6} md={5}>
													<button
														onClick={handleOpenSafra}
														type="button"
														className={
															disableSelectSafra ? styles.ButtonCancelar : styles.ButtonColher
														}
														disabled={disableSelectSafra}
													>
														Selecionar Safra
													</button>
												</Grid>
												<Grid item xs={12} sm={6} md={7}>
													<select
														id="safra"
														onChange={(e) => setSafra(e.target.value)}
														value={!disableSelectSafra ? selectedSafra : -1}
														className={styles.SelectSafra}
														disabled
													>
														<option value={-1}>Safra</option>
														{allSafras.map((item, index) => {
															return (
																<option value={item.id} key={index}>
																	{item.name}
																</option>
															);
														})}
													</select>
												</Grid>
												<Grid
													item
													xs={12}
													sx={{
														display: "flex",
														justifyContent: "start",
														textAlign: "center",
													}}
												>
													<div className={styles.checkboxContainer}>
														<input
															type="checkbox"
															className={styles.inputCheckbox}
															name="allSelect"
															id="allSafraCheckbox"
															onChange={() => setAllSafrasCheckBox()}
														/>
														<label htmlFor="allSafraCheckbox" className={styles.labelTitle}>
															<b>Selecionar todas as Safras</b>
														</label>
													</div>
												</Grid>
											</Grid>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Cultura: </p>
											<select
												onChange={(e) => setSelectedCultivo(e.target.value)}
												value={selectedCultivo}
												className={styles.Select}
											>
												<option value={-1}>Todos</option>
												{allCultivos.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.name}
														</option>
													);
												})}
											</select>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Talhão: </p>
											<select
												onChange={(e) => setSelectedTalhao(e.target.value)}
												value={selectedTalhao}
												className={styles.Select}
											>
												<option value={-1}>Todos</option>
												{allTalhoes.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															Talhao {item.nome}
														</option>
													);
												})}
											</select>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Parcela: </p>
											<select
												onChange={(e) => setSelectedSubdivisao(e.target.value)}
												value={selectedSubdivisao}
												className={styles.Select}
											>
												<option value={-1}>Todos</option>
												{allSubdivisoes.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.name}
														</option>
													);
												})}
											</select>
										</Grid>

										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Data de início:</p>
											<InputData
												placeholder="Data de início"
												onDateChange={handleDateChangeInicio}
												classN={styles.Select}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Data de finalização:</p>
											<InputData
												classN={styles.Select}
												placeholder="Data de finalização"
												onDateChange={handleDateChangeFim}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={12}
									md={3}
									sx={{
										display: "flex",
										justifyContent: { sx: "start", sm: "start", md: "end" },
										width: "max-content",
										px: 1,
									}}
								>
									<ButtonExportChart disabled={disable} href={filtroPdf()} />
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							sx={{
								display: "flex",
								alignItems: "stretch",
								width: "100%",
								minHeight: "50vh",
							}}
						>
							<div className={styles.Chart}>
								<p className={styles.ChartTitle}>Ocorrências por talhão</p>

								<Chart
									className={styles.Chart}
									data={dataTalhao}
									options={props.options}
									chartType="ColumnChart"
								/>
							</div>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							sx={{
								display: "flex",
								alignItems: "stretch",
								width: "100%",
								minHeight: "50vh",
							}}
						>
							<div className={styles.Chart}>
								<p className={styles.ChartTitle}>Ocorrências por parcela</p>{" "}
								<Chart
									data={dataParcela}
									options={props.options}
									chartType="ColumnChart"
								/>
							</div>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							sx={{
								display: "flex",
								alignItems: "stretch",
								width: "100%",
								minHeight: "50vh",
							}}
						>
							<div className={styles.Chart}>
								<p className={styles.ChartTitle}>Ocorrências por safra</p>{" "}
								<Chart
									data={dataSafra}
									options={props.options}
									chartType="ColumnChart"
								/>
							</div>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							sx={{
								display: "flex",
								alignItems: "stretch",
								width: "100%",
								minHeight: "50vh",
							}}
						>
							<div className={styles.Chart}>
								<p className={styles.ChartTitle}>Ocorrências por tipo</p>{" "}
								<Chart
									data={dataType}
									options={props.options}
									chartType="ColumnChart"
								/>
							</div>
						</Grid>
					</Grid>
				</Box>
			</Box>
			{/* safra  */}
			<Modal
				open={openSafra}
				onClose={handleCloseSafra}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={stylesItem}
						className={styles.registrationInputModal}
					>
						<Grid item xs={12} sm={12} md={12}>
							<div className={styles.titleTabelaModal}>
								<h2>
									<b>Safra</b>
								</h2>
								<HelpButtonInput
									title={"Tabela de Safras"}
									text={"Clique em cima do item desejado para seleciona-lo."}
								/>
							</div>
							<Paper sx={{ width: "100%", p: 0, m: 0 }}>
								<ThemeProvider theme={theme}>
									<TableContainer>
										<Table stickyHeader>
											<EnhancedTableHead
												className={styles.tableGenerator}
												numSelected={selected.length}
												order={order}
												orderBy={orderBy}
												onSelectAllClick={handleSelectAllClicks}
												onRequestSort={handleRequestSort}
												rowCount={dataAllSafras.length}
											/>
											<TableBody>
												{
													//cria uma linha por objeto
													visibleRows
														.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
														.map((row, index) => {
															return (
																<TableRow hover tabIndex={-1} key={index}>
																	{keysForActiveSafra.map((column) => {
																		return (
																			<TableCell
																				key={column.id}
																				id={
																					row.id +
																					"&" +
																					row.talhaoId +
																					"&" +
																					row.culturaId +
																					"&" +
																					row.subId
																				}
																				onClick={handleOpenSafraDialog}
																			>
																				{row[column.id]}
																			</TableCell>
																		);
																	})}
																</TableRow>
															);
														})
												}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
										component="div"
										colSpan={7}
										count={visibleRows.length}
										rowsPerPage={rowsPerPage}
										page={page}
										labelRowsPerPage="Linhas por pagina"
										SelectProps={{
											inputProps: {
												"aria-label": "rows per page",
											},
											native: false,
										}}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</ThemeProvider>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 0, sm: 0, md: 0 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
									pt: 2,
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseSafra}
										className={styles.ButtonCancelarModal}
									>
										Cancelar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Dialog
						open={openSafraDialog}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleCloseSafraDialog}
						aria-describedby="alert-dialog-slide-description"
					>
						<DialogContent>
							<DialogContentText id="alert-dialog-slide-description">
								Deseja Selecionar esse item?
							</DialogContentText>
						</DialogContent>
						<DialogActions sx={{ display: "flex", flexWrap: "wrap" }}>
							<button
								className={styles.ButtonCancelarModal}
								onClick={handleCloseSafraDialog}
							>
								Cancelar
							</button>

							<button
								className={styles.ButtonColherModal}
								onClick={handleCloseAddSafra}
							>
								Selecionar
							</button>
						</DialogActions>
					</Dialog>
				</Box>
			</Modal>
		</Box>
	);
}

export default RelatoriosOcorrencia;