import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import AsideMenuAdmin from "./AsideMenu/index";
import PropTypes from "prop-types";
import Logo from "../../Assets/images/logoMenu.svg";
import UserComponent from "./UserComponent";

//import css modules
/* import "./style.css"; */
import styles from "./TopMenu.module.css";

//react rout dom
import { Link } from "react-router-dom";

const drawerWidth = 250;

function TopMenuAdmin(props) {
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const drawer = (
		<Box sx={{ textAlign: "center" }}>
			<AsideMenuAdmin open={props.open} />
		</Box>
	);

	return (
		<>
			<AppBar component="nav" sx={{ bgcolor: "#00A69A", p: 0, m: 0 }}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{
							display: "flex",
							alignItems: "center",
							flexDirection: "row",
						}}
					>
						<MenuIcon />
					</IconButton>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: 90,
							width: "100%",
						}}
					>
						<Link to="/admin/empresas">
							<img className={styles.imgLogo} src={Logo} />
						</Link>
					</Box>
					<UserComponent />
				</Toolbar>
			</AppBar>
			<AppBar component="nav">
				<Drawer
					open={mobileOpen}
					onClose={handleDrawerToggle}
					sx={{
						display: "block",
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
			</AppBar>
		</>
	);
}

TopMenuAdmin.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default TopMenuAdmin;
