//material ul imports
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import RegisterArea from "../../../components/RegistrationArea";
import TopMenuAdmin from "../../../components/TopMenuAdmin";
import { CultivationService } from "../../../service/cultivation_service";
import styles from "./CultureCad.module.css";

import "./style.css";
import Loading from "../../../components/Loading";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function AdminFarmCad() {
	const [searchParams] = useSearchParams();
	const [farm, setFarm] = useState({packages:[]});
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [errorMessageCulture, setErrorMessageCulture] = useState(false);
	const [id, setId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const cultureRef = useRef(null);

	const cultivationService = new CultivationService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const farmId = searchParams.get("id");

		if (farmId !== null) {
			setId(farmId);
			const farmToEdit = await cultivationService.getById(farmId);
			setFarm(farmToEdit);
		}
		setIsLoading(false);
	};

	const validationSave = async () => {
		if (farm.cultura && farm.cultura !== "") {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			setErrorMessageCulture(false);
			return true;
		} else {
			let message = "Um ou mais campos vazios. - (";
			if (!farm.cultura || farm.cultura === "") {
				message += "Cultura, ";
				cultureRef.current?.focus();
				setErrorMessageCulture(true);
			} else {
				setErrorMessageCulture(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};
	const handleSave = async () => {
		if (!id) {
			const farmToSave = {
				cultivation: farm.cultura,
				variety: farm.variedade
			};

			await cultivationService.save(farmToSave);
		}else{
			const farmToEdit = {
				id: farm.id,
				cultivation: farm.cultura,
				variety: farm.variedade
			};
			await cultivationService.update(farm.id,farmToEdit);
		}
	};
	
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenuAdmin open={["admin", "farms"]}/>
				{/* href defini a volta  title defini o titulo da pagina*/}
				<Box component="main" sx={{ p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							sx={{ display: "flex", width: "100%" }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item>
									<RegisterArea
										title="Cadastro de Cultura"
										href="/admin/culturas"
										salveFunc={validationSave}
										typeMessage={typeMessage}
										message={message}
									>
										{/* inputs da pagina de resgitro */}
										<Grid
											container
											rowSpacing={1}
											columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										>
											<Grid
												item
												xs={12}
												sm={12}
												md={6}
												sx={{
													display: "flexbox",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<div
													className={
														errorMessageCulture
															? styles.errControl
															: styles.noErrControl
													}
												>	<	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,backgroundColor:"white",
																visibility:(farm.cultura && farm.cultura !== "" ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
																Cultura *
														</Typography>
		
														<p
															className={
																errorMessageCulture
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>
														<input 
															ref={cultureRef}
															placeholder="Cultura *" 
															className={styles.selectInputPlaceholder} 
															value={farm.cultura} 
															onChange={(e)=>setFarm({...farm, cultura: e.target.value})}
														/>
													</Box>
												</div>
											</Grid>
											<Grid
												item
												xs={12}
												sm={12}
												md={6}
												sx={{
													display: "flexbox",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<div className={styles.helpPlusInput}>
													<	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(farm.variedade && farm.variedade !== ""? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
																Variedade
														</Typography>
		
														<input 
															placeholder="Variedade" 
															className={styles.selectInputPlaceholder} 
															value={farm.variedade} 
															onChange={(e)=>setFarm({...farm, variedade: e.target.value})}
														/>
													</Box>
												</div>
											</Grid>
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}

export default AdminFarmCad;
