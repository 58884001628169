import Box from "@mui/material/Box";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./TableHarvestForecast.module.css";

//import material-ui
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import ButtonTable from "../../Buttons/buttonTable";
//test

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Slide,
	TableHead,
	TableSortLabel,
} from "@mui/material";
import Loading from "../../Loading";
import { HarvestService } from "../../../service/harvest_service";
import { captalizeFirstLetterEacheWord, getDateWithoutTimezone } from "../../../utils/utils";
import { CropSeasonService } from "../../../service/crop_season_service";
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort} =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const keys = ["safra", "data"];

const headCells = [
	{
		id: "safra",
		label: "Safra",
	},
	{
		id: "data",
		label: "prevista",
	},

];
function HarvestForecast(props) {
	const [page, setPage] = React.useState(0); //page tabela
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //rows per page
	const [open, setOpen] = useState(false); //modal
	const [indexDialog, setIndexDialog] = useState("");
	const [selected, setSelected] = useState([]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("safra");

	const [isLoading, setIsLoading] = useState(true);
	const [harvestData, setHarvestData] = useState([]);
	const [totalHarvestData, setTotalHarvestData] = useState(0);

	const harvestService = new HarvestService();
	const cropSeasonService = new CropSeasonService();

	useEffect(() => {
		updateHarvestData();
	}, [rowsPerPage, page]);
	const updateHarvestData = async () => {
		setIsLoading(true);
		const user = JSON.parse(localStorage.getItem("user"));
		const propertyId = localStorage.getItem("propertyId");
		let orderByValue =  JSON.stringify(
			{
				createdAt: "desc"
			}
		);

		if(orderBy === headCells[0].id){
			orderByValue =  JSON.stringify(
				[
					{
						propriedadeCultivo: {
							cultivo: {
								cultura: order
							}
						}
					},
					{
						propriedadeCultivo: {
							cultivo: {
								variedade: order
							}
						}
					}
				]
			);
		} else if(orderBy === headCells[1].id){
			orderByValue =  JSON.stringify(
				{
					dataPrevista: order
				}
			);
		}

		const forecastParams = {
			page: page + 1,
			limit: rowsPerPage,
			filterValue: encodeURI(JSON.stringify({previsao: true, solicitanteColheita: user.id})),
			orderBy: orderByValue
		};
		
		const forecast = await harvestService.getByProperty(propertyId, forecastParams);

		setTotalHarvestData(forecast.totalItems);
		setHarvestData(await getForecastDetails(forecast.harvests, user.admin));

		setIsLoading(false);
	};

	const getForecastDetails = async (lotes) => {
		const lotesDetails = [];
		for (let i = 0; i < lotes.length; i++) {
			const lote = lotes[i];
			const loteSafras = lote.loteSafras;
			const loteSafra = loteSafras[0];
			const safraname = await getSafraName(loteSafra.safraId);
			const date = getDateWithoutTimezone(lote.dataPrevista);
			const dateString =
				("0" + date.getUTCDate()).slice(-2) +
				"/" +
				("0" + (date.getUTCMonth() + 1)).slice(-2) +
				"/" +
				date.getUTCFullYear();
			
			let newColheita = {
				id: lote.id,
				itemId: loteSafra.safraId,
				loteId: lote.id,
				data: dateString,
				safra: safraname,
			};
			lotesDetails.push(newColheita);
		}
		return lotesDetails;
	};

	const getSafraName = async (safraId) => {
		const safra = await cropSeasonService.getById(safraId);
		const talhao = safra.talhao;
		let safraname = `Talhão ${talhao.nome}`;
		if (safra.subdivisaoId !== null && safra.subdivisaoId !== undefined) {
			const subdivisao = safra.subdivisao;
			const subdivisaoCategory = subdivisao.categoriaSubdivisao;
			safraname += ` - ${captalizeFirstLetterEacheWord(subdivisaoCategory)} ${subdivisao.complemento}`;
		
		}
		const propertyCulture = safra.propriedadeCultivo;
		const cultivo = propertyCulture.cultivo;
		safraname += ` - ${captalizeFirstLetterEacheWord(cultivo.cultura)} ${captalizeFirstLetterEacheWord(cultivo.variedade ?? "")}`;
		return safraname;
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = dataFilter.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //seta a pagina atual tabela
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //seta a quantidade de linhas por pagina
		setPage(0); //seta a pagina atual tabela
	};

	const dataFilter = harvestData.filter(
		(item) => item.safra.toLowerCase().includes(props.search.toLowerCase())
		|| item.data.toString().includes(props.search.toLowerCase())
	);
	const handleClickOpen = (index) => {
		setOpen(true);
		setIndexDialog(index);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleCloseAndAdd = (index) => {
		setOpen(false);
		const loteId = harvestData[index].loteId;
		window.location.href=`/colheita/cadastroColheita?id=${loteId}`;
	};
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};
	function descendingComparator(a, b, orderBy) {
		if (orderBy === "data_solicitacao") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}
		if (orderBy === "data_realizacao") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}

		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(dataFilter, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage,dataFilter]
	);
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<Paper>
					<TableContainer>
						<Table stickyHeader>
							<EnhancedTableHead
								className={styles.tableGenerator}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClicks}
								onRequestSort={handleRequestSort}
								rowCount={dataFilter.length}
								columns={props.columns}
							/>
							<TableBody>
								{
									//cria as linhas da tabela
									visibleRows
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											return (
												<TableRow
													hover
													tabIndex={-1}
													key={row.id}
													sx={{ height: 3 }}
												>
													{keys.map((column) => {
														//seta as colunas
														return (
															<TableCell id={row.id_cuture} key={column}>
																{row[column]}
															</TableCell>
														);
													})}
													<TableCell sx={{ width: "30%" }}>
														<button
															className={styles.harvestButton}
															onClick={() => handleClickOpen(index)}
															id={row.cuture}
														>
															Confirmar
														</button>
													</TableCell>
													<TableCell>
														<ButtonTable
															className={styles.btn}
															editHref={`/colheita/previssaoColheita?id=${row.id}`}
															deletFunc={() => props.handleDelete(row.id)}
															deleteMessage="Deseja deletar? (Todos os pedidos e perdas relacionados também serão excluídos)"
														/>
													</TableCell>
												</TableRow>
											);
										})
								}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[7, 20, 30]}
						component="div"
						colSpan={7}
						count={totalHarvestData}
						rowsPerPage={rowsPerPage}
						page={page}
						labelRowsPerPage="Linhas por pagina"
						SelectProps={{
							inputProps: {
								"aria-label": "rows per page",
							},
							native: false,
						}}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
					/>
				</Paper>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleClose}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Deseja finalizar?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<button className={styles.ButtonCancelarModal} onClick={handleClose}>
							Cancelar
						</button>
						<button
							className={styles.ButtonColherModal}
							onClick={() => handleCloseAndAdd(indexDialog)}
						>
							Confirmar
						</button>
					</DialogActions>
				</Dialog>
			</div>
		);
}

export default HarvestForecast;
