import { OccurrencesRepository } from "../repository/occurences_repository";

export class OccurrencesService {

	constructor() {
		this.occurencesRepository = new OccurrencesRepository();
	}

	async save(occurences) {
		const savedOccurrences = await this.occurencesRepository.save(occurences);
		return savedOccurrences;
	}

	async update(occurences) {
		const updatedOccurrences = await this.occurencesRepository.update(occurences);
		return updatedOccurrences;
	}

	async saveCategory(category) {
		const savedCategory = await this.occurencesRepository.saveCategory(category);
		return savedCategory;
	}

	async updateCategory(category) {
		const updatedCategory = await this.occurencesRepository.updateCategory(category);
		return updatedCategory;
	}

	async changeVisibility(id) {
		await this.occurencesRepository.changeVisibility(id);
	}

	async getByProperty(propertyId, params) {
		const occurencesData = await this.occurencesRepository.getByProperty(propertyId, params);
		return occurencesData;
	}

	async getByOccurrencesCropSeason(propertyId, params) {
		const occurencesData = await this.occurencesRepository.getByOccurrencesCropSeason(propertyId, params);
		return occurencesData;
	}

	async deleteIncidentCropSeason(cropSeasonId,incidentId) {
		const occurencesData = await this.occurencesRepository.deleteIncidentCropSeason(cropSeasonId,incidentId);
		return occurencesData;
	}

	async getById(occurencesId) {
		const occurences = await this.occurencesRepository.getById(occurencesId);
		return occurences;
	}

	async getCategoryById(id) {
		const category = await this.occurencesRepository.getCategoryById(id);
		return category;
	}

	async getImages(occurencesId) {
		const occurences = await this.occurencesRepository.getImages(occurencesId);
		return occurences;
	}

	async getAllCategories() {
		const occurences = await this.occurencesRepository.getAllCategories();
		return occurences;
	}

	async getAllTypes(params) {
		const occurences = await this.occurencesRepository.getAllTypes(params);
		return occurences;
	}

	async delete(occurencesId) {
		const occurences = await this.occurencesRepository.delete(occurencesId);
		return occurences;
	}

	async getTypeById(id) {
		const type = await this.occurencesRepository.getTypeById(id);
		return type;
	}

	async saveType(type) {
		const savedType = await this.occurencesRepository.saveType(type);
		return savedType;
	}

	async updateType(type) {
		const updatedtype = await this.occurencesRepository.updateType(type);
		return updatedtype;
	}

	async changeCategoryVisibility(id) {
		await this.occurencesRepository.changeCategoryVisibility(id);
	}

	async changeTypeVisibility(id) {
		await this.occurencesRepository.changeTypeVisibility(id);
	}
}