import { LicenseRepository } from "../repository/license_repository";

export class LicenseService {

	constructor() {
		this.licenseRepository = new LicenseRepository();
	}

	async save(license) {
		return await this.licenseRepository.save(license);
	}

	async update(licenseId, license) {
		return await this.licenseRepository.update(licenseId, license);
	}

}