import React, { useEffect, useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";
import TopMenuAdmin from "../../components/TopMenuAdmin";
import "../CommonStyle/style.css";

//import components
import Search from "../../components/SearchHeader";
import Table from "../../components/TableGenerator";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { captalizeFirstLetterEacheWord, getComparator, stableSort } from "../../utils/utils";
import Loading from "../../components/Loading";
import { OccurrencesService } from "../../service/occurrences_service";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//column
const columns =[
	{
		id: "category",
		label: "Categoria",
	},
	{
		id: "createdAt",
		label: "Data de criação",
	},

];

function AdminIncident() {
	const [search, setSearch] = useState("");
	const [categories, setCategories] = useState([]);
	const [allCategories, setAllCategories] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const incidentsService = new OccurrencesService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const allCategories = await incidentsService.getAllCategories();
		const categoriesToShow = allCategories.map((category) => {
			return {
				id: category.id,
				category: captalizeFirstLetterEacheWord(category.nome),
				createdAt: new Date(category.createdAt).toLocaleDateString("pt-BR")
			};
		});
		setAllCategories(categoriesToShow);
		setIsLoading(false);
		setCategories(
			stableSort(categoriesToShow, getComparator("asc", columns[0].id))
				.slice(0,7));
	};

	const updateData = async (page, rowsPerPage, orderBy, order) => {
		setCategories(stableSort(allCategories, getComparator(order, orderBy)).slice(
			page * rowsPerPage,
			page * rowsPerPage + rowsPerPage
		));
	};

	const dataFilter = categories.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	function handleClick(e) {
		setSearch(e.target.value);
	}

	const handleDelete = async (id) => {
		await incidentsService.changeCategoryVisibility(id);
		await initData();
	};

	const custonOp = [
		{
			item: "Tipos",
			func: function handleOpen(id) {
				window.location = `./ocorrencias/${id}/tipos`;
			},
		},
	];

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenuAdmin open={["admin", "incidents"]}/>
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Search
										title="Categorias de ocorrência"
										href="/admin/ocorrencias/cadastro"
										textButton="Nova Categoria"
									>
										<InputSearch func={handleClick} />
									</Search>
								</Item>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Table
										listSize={allCategories.length}
										data={dataFilter}
										columns={columns}
										handleDelete={handleDelete}
										editHref="/admin/ocorrencias/cadastro?id="
										custonOpition={custonOp}
										updateData={updateData}
										// onclickRow={"/culturas/Embalagens"}
									/>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}

export default AdminIncident;
