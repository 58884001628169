import React, { useEffect, useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";
import TopMenuAdmin from "../../components/TopMenuAdmin";
import "../CommonStyle/style.css";

//import components
import Search from "../../components/SearchHeader";
import Table from "../../components/TableGenerator";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { captalizeFirstLetterEacheWord, getComparator, stableSort } from "../../utils/utils";
import Loading from "../../components/Loading";
import { GroupInputService } from "../../service/group_input_service";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//column
const columns =[
	{
		id: "group_input",
		label: "Nome do grupo",
	},
	{
		id: "createdAt",
		label: "Data de criação",
	},

];

function AdminGroupInput() {
	const [search, setSearch] = useState("");
	const [groups, setGroups] = useState([]);
	const [allGroups, setAllGroups] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const groupInputService = new GroupInputService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const allGroups = await groupInputService.get();
		const groupsToShow = allGroups.map((farm) => {
			return {
				id: farm.id,
				group_input: captalizeFirstLetterEacheWord(farm.nome),
				createdAt: new Date(farm.createdAt).toLocaleDateString("pt-BR")
			};
		});
		setAllGroups(groupsToShow);
		setIsLoading(false);
		setGroups(
			stableSort(groupsToShow, getComparator("asc", columns[0].id))
				.slice(0,7));
	};

	const updateData = async (page, rowsPerPage, orderBy, order) => {
		setGroups(stableSort(allGroups, getComparator(order, orderBy)).slice(
			page * rowsPerPage,
			page * rowsPerPage + rowsPerPage
		));
	};

	const dataFilter = groups.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	function handleClick(e) {
		setSearch(e.target.value);
	}

	const handleDelete = async (id) => {
		await groupInputService.changeVisibility(id);
		await initData();
	};

	const custonOp = [
		{
			item: "Insumos",
			func: function handleOpen(id) {
				window.location = `./grupo-insumos/${id}/insumos`;
			},
		},
	];

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenuAdmin open={["admin", "inputs"]}/>
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Search
										title="Grupos de Insumos"
										href="/admin/grupo-insumos/cadastro"
										textButton="Novo Grupo"
									>
										<InputSearch func={handleClick} />
									</Search>
								</Item>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Table
										listSize={allGroups.length}
										data={dataFilter}
										columns={columns}
										handleDelete={handleDelete}
										editHref="/admin/grupo-insumos/cadastro?id="
										custonOpition={custonOp}
										updateData={updateData}
										// onclickRow={"/culturas/Embalagens"}
									/>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}

export default AdminGroupInput;
