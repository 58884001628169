import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import TopMenuAdmin from "../../../../components/TopMenuAdmin";
import styles from "./serviceCad.module.css";

//material ul imports
import { Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//components
import RegisterArea from "../../../../components/RegistrationArea";
import Loading from "../../../../components/Loading";
import { OccurrencesService } from "../../../../service/occurrences_service";

function AdminIncidentTypeCad() {
	const { categoryId } = useParams();

	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const [typeInCategory, setTypeInCategory] = useState({});
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [errorMessageName, setErrorMessageName] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const nameRef = useRef(null);

	const incidentService = new OccurrencesService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		if(id){
			const type = await incidentService.getTypeById(id);
			setTypeInCategory(type);
		}
		setIsLoading(false);
	};

	const validationSave = async () => {
		if (typeInCategory.nome) {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			setErrorMessageName(false);
			return true;
		} else {
			let message = "Um ou mais campos vazios. - (";
			if (!typeInCategory.nome) {
				message += "Nome, ";
				nameRef.current.focus();
				setErrorMessageName(true);
			} else {
				setErrorMessageName(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};

	const handleSave = async () => {
		let typeToSave = {
			name: typeInCategory.nome,
			incidentCategoryId: categoryId,
		};

		if (!id){
			await incidentService.saveType(typeToSave);
		} else {
			typeToSave.id = id;
			await incidentService.updateType(typeToSave);
		}
	};

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenuAdmin
					open={["admin", "incidents"]}
				/>
				<Box component="main" sx={{ p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							sx={{ display: "flex" }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item>
									<RegisterArea
										title={
											id
												? "Editar Tipo de ocorrência"
												: "Cadastro de Tipo de ocorrência"
										}
										href={`/admin/ocorrencias/${categoryId}/tipos`}
										salveFunc={validationSave}
										typeMessage={typeMessage}
										message={message}
									>
										{/* inputs da pagina de resgitro */}
										<Grid
											container
											rowSpacing={1}
											columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										>
											<Grid item xs={12} sm={12} md={6}>
												<div
													className={
														errorMessageName
															? styles.errControl
															: styles.noErrControl
													}
												>
													<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(typeInCategory.nome ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															Nome *
														</Typography>
														<p
															className={
																errorMessageName
																	? styles.errText
																	: styles.noErrText
															}
														>
															*
														</p>

														<input
															ref={nameRef}
															value={typeInCategory.nome}
															onChange={e => setTypeInCategory({...typeInCategory, nome: e.target.value})}
															type="text"
															placeholder="Nome *"
															id="name"
														/>
													</Box>
												</div>
											</Grid>
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}
export default AdminIncidentTypeCad;
