import React, { useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";

//import css styles
import "../CommonStyle/style.css";

//import components
import Search from "../../components/SearchHeader";
import Table from "../../components/TableGenerator";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Loading from "../../components/Loading";
import { BusinessService } from "../../service/business_service";
import { getDateWithoutTimezone } from "../../utils/utils";
import TopMenuAdmin from "../../components/TopMenuAdmin";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//column
const columns = [
	{
		id:"razaoSocial",
		label: "Razão social",
	},
	{
		id: "nomeFantasia",
		label: "Nome fantasia",
	},
	{
		id: "licenca",
		label: "Período da Licença",
	}
];

function Business() {
	const [search, setSearch] = useState("");
	const [companies, setCompanies] = useState([]);
	const [totalBusinessData, setTotalBusinessData] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const businessService = new BusinessService();

	const updateData = async (page, rowsPerPage) => {
		const params = {
			page: page + 1,
			limit: rowsPerPage,
			filterValue: {},
			sortBy: JSON.stringify([
				{
					nome: "asc"
				}
			]),
		};

		let business = await businessService.get(params);
		setTotalBusinessData(business.totalItems);

		business = business.business.map((businessItem) => {
			
			return {
				id: businessItem.id,
				razaoSocial: businessItem.nome,
				nomeFantasia: businessItem.nome_fantasia ?? "-",
				licenca: businessItem.licensa?.data_inicio && businessItem.licensa?.data_fim ?
					`${getDateWithoutTimezone(businessItem.licensa?.data_inicio).toLocaleDateString("pt-BR")} - ${getDateWithoutTimezone(businessItem.licensa?.data_fim).toLocaleDateString("pt-BR")}`
					: "Sem licença",
			};
		});
		setCompanies(business);
		setIsLoading(false);
	};

	const dataFilter = companies.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	function handleClick(e) {
		setSearch(e.target.value);
	}

	const handleDelete = async (id) => {
		await businessService.changeVisibility(id);
	};

	const custonOp = [
		{
			item: "Usuários",
			func: function handleOpen(id) {
				window.location = `./empresas/${id}/usuarios`;
			},
		},
	];

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenuAdmin open={["admin", "business"]} />
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Search
										title="Empresa"
										href="/admin/empresas/cadastro"
										textButton="Nova Empresa"
									>
										<InputSearch func={handleClick} />
									</Search>
								</Item>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
										p: 0,
										m: 0,
									}}
								>
									<Table
										custonOpition={custonOp}
										listSize={totalBusinessData}
										updateData={updateData}
										data={dataFilter}
										columns={columns}
										handleDelete={handleDelete}
										editHref="/admin/empresas/cadastro?id="
									/>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}

export default Business;
