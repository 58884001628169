import React, { useEffect, useRef, useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";
import TopMenu from "../../components/TopMenu";
import "../CommonStyle/style.css";

//import components
import Search from "../../components/SearchHeader";
import Table from "../../components/TableGenerator";

//material ul imports
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import styles from "./Client.module.css";

import { CustomerService } from "../../service/customer_service";
import { FarmService } from "../../service/farm_service";
import Loading from "../../components/Loading";
import { getComparator, stableSort } from "../../utils/utils";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	transform: "translate(-50%, -50%)",
};

//column
const columns = [
	{
		id: "nome",
		label: "Nome",
	},
	{
		id: "nomeFantasia",
		label: "Apelido ou Nome Fantasia",
	},
	{
		id: "cpf_cnpj",
		label: "CNPJ/CPF",
	},
	{
		id: "identificador_estrangeiro",
		label: "Identificador Estrangeiro",
	},
	{
		id: "telefone",
		label: "Telefone",
	}
];

function Client() {
	const [search, setSearch] = useState("");
	const [customers, setCustomers] = useState([]);
	const [allClients, setAllClients] = useState([]);
	const [open, setOpen] = useState(false); //modal
	const [selectedCulture, setSelectedCulture] = useState(null);
	const [currentClient, setCurrentClient] = useState(null);
	const [allCultures, setAllCultures] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const barCode = useRef("");

	const customerService = new CustomerService();
	const farmService = new FarmService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const customerParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					nome: "asc"
				}
			])
		};
		let customersInProperty = await customerService.getByProperty(customerParams, propertyId);
		customersInProperty = customersInProperty.map((customer) => {
			customer.nome = customer.nome || "";
			customer.nomeFantasia = customer.nomeFantasia || "";
			customer.cpf_cnpj = customer.cpf_cnpj || "";
			customer.identificador_estrangeiro = customer.identificador_estrangeiro || "";
			customer.telefone = customer.telefone || "";
			return customer;
		});
		setAllClients(customersInProperty);
		setCustomers(stableSort(customersInProperty, getComparator("asc", columns[0].id)).slice(
			0, 7
		));

		const farmParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					cultivo: {
						cultura: "asc"
					}
				},
				{
					cultivo: {
						variedade: "asc"
					}
				}
			])
		};

		const farmsInProperty = await farmService.get(farmParams, propertyId);
		setAllCultures(farmsInProperty.farms);
		setIsLoading(false);
	};

	const updateData = async (page, rowsPerPage, orderBy, order) => {
		setCustomers(stableSort(allClients, getComparator(order, orderBy)).slice(
			page * rowsPerPage,
			page * rowsPerPage + rowsPerPage
		));
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedCulture("-1");
		barCode.current.value = "";
	};

	const changeFarm = async (farmId) => {
		const customer = customers.find((customer) => customer.id === currentClient);
		const currentBarCode = customer.codigoDeBarraCultivoClientes.find((farmBarCode) => farmBarCode.propriedadecultivoId === farmId);
		if(currentBarCode)
			barCode.current.value = currentBarCode.codigoDeBarra;
		else
			barCode.current.value = "";
		setSelectedCulture(farmId);
	};

	const handleSaveBarCode = async () => {
		if (
			barCode.current.value &&
			barCode.current.value !== "" &&
			selectedCulture &&
			selectedCulture !== "" &&
			currentClient
		) {
			const customer = customers.find((customer) => customer.id === currentClient);
			const currentBarCode = customer.codigoDeBarraCultivoClientes.find((farmBarCode) => farmBarCode.propriedadecultivoId === selectedCulture);

			const barCodeToSave = {
				barcode: barCode.current.value,
				farmPropertyId: selectedCulture,
				clientId: currentClient
			};

			if(currentBarCode) {
				barCodeToSave.id = currentBarCode.id;
				await customerService.updateBarCode(barCodeToSave);
			}else {
				await customerService.saveBarCode(barCodeToSave);
			}
			setSelectedCulture(null);
			barCode.current.value = "";
			setOpen(false);
			initData();
		}
	};

	const dataFilter = customers.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);

	function handleClick(e) {
		setSearch(e.target.value);
	}

	const handleDelete = async (id) => {
		const propertyId = localStorage.getItem("propertyId");
		await customerService.changeVisibility(id, propertyId);
		initData();
	};
	const custonOp = [
		{
			item: "Código de barras",
			func: function handleOpen(id) {
				setOpen(true); //abre o modal
				setCurrentClient(id);
			},
		},
	];
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu open={["register", "client"]}/>
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Search
										title="Clientes"
										href="/clientes/cadastroCliente"
										textButton="Novo Cliente"
										help="client"
									>
										<InputSearch func={handleClick} />
									</Search>
								</Item>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
										p: 0,
										m: 0,
									}}
								>
									<Table
										listSize={allClients.length}
										data={dataFilter}
										columns={columns}
										editHref="/clientes/cadastroCliente?id="
										handleDelete={handleDelete}
										custonOpition={custonOp}
										updateData={updateData}
									/>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "90%", md: "80%" },
							bgcolor: "white",
							borderRadius: "8px",
							p: 2,
							m: 0,
						}}
					>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<p className={styles.registrationTitle}>
									Cadastrar código de barras
								</p>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									className={styles.registrationInputModal}
								>
									<Grid item xs={12} sm={6} md={6}>
										<select
											id="culture"
											value={selectedCulture}
											onChange={(e) => changeFarm(e.target.value)}
											className={styles.selectInputPlaceholder}
										>
											<option value={-1}>
												{selectedCulture ? "---------------" : "Produto"}
											</option>
											{allCultures.map((item, index) => (
												<option value={item.id} key={index}>
													{item.cultivo.cultura} {item.cultivo.variedade ?? ""}
												</option>
											))}
										</select>
									</Grid>
									<Grid item xs={12} sm={6} md={6}>
										<div className={styles.registrationInputModal}>
											<input
												type="text"
												placeholder="Código de barra"
												ref={barCode}
											/>
										</div>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Item sx={{ border: "none", boxShadow: "none" }}>
									<Grid
										container
										rowSpacing={0}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "end",
											flexDirection: {
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											},
										}}
									>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={{ border: "none", boxShadow: "none" }}>
												<button
													type="button"
													onClick={handleClose}
													className={styles.registrationButtonCancelar}
												>
													Cancelar
												</button>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={{ border: "none", boxShadow: "none" }}>
												<button
													className={styles.registrationButtoncadastrar}
													type="button"
													onClick={handleSaveBarCode}
												>
													Cadastrar
												</button>
											</Item>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			</div>
		);
}

export default Client;
