import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useEffect } from "react";
import { useState } from "react";
import { dataHelp } from "./dataHelp";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Tooltip } from "@mui/material";

export default function HelpButton(props) {
	const [page, setPage] = useState({});
	const helpfind = () => {
		if (props.help !== undefined) {
			const help = dataHelp.find((help) => help.identification === props.help);
			setPage(help);
		}
	};
	useEffect(() => {
		helpfind();
	}, []);

	return (
		<PopupState variant="popover" sx={{ width: "50%" }}>
			{(popupState) => (
				<div>
					<Tooltip title="Clique aqui para detalhes">
						<Button
							sx={{
								borderRadius: "100%",
								width: 30,
								minWidth: 30,
								height: 30,
								p: 0,
								m: 0,
								color: "#5b5b5d",
							}}
							{...bindTrigger(popupState)}
						>
							<InfoOutlinedIcon sx={{ fontSize: "30px" }} />
						</Button>
					</Tooltip>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: props.verticalAnchorOrigin,
							horizontal: props.horizontalAnchorOrigin,
						}}
						transformOrigin={{
							vertical: props.verticalTransformOrigin,
							horizontal: props.horizontalTransformOrigin,
						}}
						sx={{ maxHeight: "95vh" }}
					>
						<Typography
							sx={{ p: 3, width: { sx: "90vw", sm: "50vw", md: "35vw" } }}
						>
							<b>{page.title}</b>
						</Typography>
						<Typography
							sx={{ p: 3, width: { sx: "90vw", sm: "50vw", md: "35vw" } }}
						>
							{page.help}
						</Typography>
						<Typography
							sx={{ p: 3, width: { sx: "90vw", sm: "50vw", md: "35vw" } }}
						>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography>
										<b>Suport</b>
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Accordion>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<Typography>
												<b>Telefone /WhatsApp:</b>
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>
												• <b>Larissa:</b> (85) 98131-0242.
											</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel2a-content"
											id="panel2a-header"
										>
											<Typography>
												<b>E-mail:</b>
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>
												<a href="mailto:suportesisagri@gmail.com">
													• suportesisagri@gmail.com
												</a>
											</Typography>
											<Typography>
												<a href="mailto:atendimento@sisagri.com.br">
													• atendimento@sisagri.com.br
												</a>
											</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel3a-content"
											id="panel4a-header"
										>
											<Typography>
												<b>Acesso/download da Plataforma Sisagri</b>
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>
												Para acessar a <b>plataforma web</b>, acesse o site:
												<a href="www.sisagri.com.br">www.sisagri.com.br</a>
											</Typography>
											<Typography>
												Para acessar o <b>aplicativo mobile</b>, acesse as
												plataformas:
												<Typography>
													App Store (IOS):
													<a href="www.sisagri.com.br"> www.sisagri.com.br</a>
												</Typography>
												<Typography>
													Play Store (Android):
													<a href="www.sisagri.com.br"> www.sisagri.com.br</a>
												</Typography>
											</Typography>
										</AccordionDetails>
									</Accordion>
								</AccordionDetails>
							</Accordion>
						</Typography>
					</Popover>
				</div>
			)}
		</PopupState>
	);
}
