import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Button, Grid, Menu, MenuItem } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import * as ExcelJS from "exceljs";

import styles from "./RelatorioGeral.module.css";

import InputData from "../../InputData";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";

import { FarmService } from "../../../service/farm_service";
import { PropertyService } from "../../../service/property_service";
import { ReportService } from "../../../service/report_service";

const subdivisionCategories = {
	PARCELA: "Parcela",
	SETOR: "Setor",
	CANTEIRO: "Canteiro",
	BANCADA: "Bancada",
	BALCAO: "Balcão",
	BLOCO: "Bloco",
	GLEBA: "Gleba",
	QUADRA: "Quadra",
	LOTE: "Lote",
};

function RelatorioGeral(props) {
	const [allCultivos, setAllCultivos] = useState([]);
	const [selectedCultivo, setSelectedCultivo] = useState(-1);
	const [personalizedStartDate, setPersonalizedStartDate] = useState(null);
	const [personalizedFinalDate, setPersonalizedFinalDate] = useState(null);
	const [allTalhoes, setAllTalhoes] = useState([]);
	const [selectedTalhao, setSelectedTalhao] = useState(-1);
	const [allSubdivisoes, setAllSubdivisoes] = useState([]);
	const [selectedSubdivisao, setSelectedSubdivisao] = useState(-1);
	const [csvData, setCsvData] = useState(null);
	const [user, setUser] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const dataNow = getCurrentDate();

	const farmService = new FarmService();
	const propertyService = new PropertyService();
	const reportService = new ReportService();

	let headerExcel = [];
	headerExcel.push(
		{
			header: "RELATÓTIO GERAL",
			key: "coluna1",
		},
		{
			header: csvData?.empresa?.nome ?? "Empresa",
			key: "coluna2",
		},
		{ header: `Propriedade: ${csvData?.propriedade?.nome} | Usuario: ${
			user?.name ?? "Não informado"} | Área: ${csvData?.area?.nome ?? "Todas"}
			| Cultivo: ${csvData?.produto?.nome ?? "Todos"}`, key: "coluna3" },
	);

	function getCurrentDate() {
		let newDate = new Date();
		let date_raw = newDate.getDate();
		let month_raw = newDate.getMonth() + 1;
		let year = newDate.getFullYear();
		var date, month;

		if (date_raw < 10) date = "0" + date_raw.toString();
		else date = date_raw.toString();
		if (month_raw < 10) month = "0" + month_raw.toString();
		else month = month_raw.toString();

		return `${date}-${month}-${year}`;
	}

	const handleDateChangeInicio = (date) => {
		setDataInico(new Date(date).toLocaleDateString("pt-BR"));
	};
	const handleDateChangeFim = (date) => {
		setDataFim(new Date(date).toLocaleDateString("pt-BR"));
	};

	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		fetchGeralData();
	}, [selectedCultivo, selectedSubdivisao, selectedTalhao, personalizedFinalDate, personalizedStartDate]);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");

		const user = JSON.parse(localStorage.getItem("user"));
		setUser(user);

		const farms = await farmService.get({}, propertyId);
		setAllCultivos(getCultivos(farms.farms));

		const plotsInProperty = await propertyService.getPlots(propertyId);

		const plots = plotsInProperty.map((plot) => {
			return {...plot, subdivisoes: getSubdivisions(plot.subdivisoes)};
		});

		setAllTalhoes(plots);
		setSelectedTalhao(-1);
		
		fetchGeralData();
	};

	const getCultivos = (cultivosInProperty) => {
		const cultivos = [];
		for (let i = 0; i < cultivosInProperty.length; i++) {
			const cultivoInProperty = cultivosInProperty[i];

			const name = getCultivoName(cultivoInProperty);
			cultivos.push({
				id: cultivoInProperty.id,
				item: cultivoInProperty,
				name: name,
			});
		}
		return cultivos;
	};

	const getCultivoName = (cultivoInProperty) => {
		const cultivo = cultivoInProperty.cultivo;

		return captalizeFirstLetterEacheWord(cultivo.cultura + " " + (cultivo.variedade ?? ""));
	};

	const getSubdivisions = (subdivisions) => {
		if(!subdivisions || subdivisions.length === 0)
			return [];
		
		let disponibleSubdivisions = [];

		subdivisions.forEach((subdivision) => {
			const disponibleChildren = getSubdivisions(subdivision.subdivisoes);

			disponibleChildren.forEach(child => {
				child = {
					nome: `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento} - ${child.nome}`,
					id: child.id
				};
				disponibleSubdivisions.push(child);
			});
			
			subdivision = {
				nome: `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento}`,
				id: subdivision.id
			};
			disponibleSubdivisions.push(subdivision);
		});

		return disponibleSubdivisions;
	};

	const fetchGeralData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		let startDate = getStartDateIncio();
		let finalDate = getFinalDateFinal();
		let currentDate = new Date();
		let currentMonth = currentDate.getMonth() + 1;
		if (finalDate === null) {
			finalDate = `${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
		}
		const params = {
			farm: String(selectedCultivo) !== "-1" && String(selectedCultivo) !== "0" ? selectedCultivo : null,
			plot: String(selectedTalhao) !== "-1" ? selectedTalhao : null,
			subdivision: String(selectedSubdivisao) !== "-1" ? selectedSubdivisao : null,
			property: propertyId,
			startDate,
			finalDate,
		};
		
		const notebookFieldData = await reportService.getGeneralData(params);
		setCsvData(notebookFieldData);
	};

	const getStartDateIncio = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		let dataR = null;
		if(personalizedStartDate){
			const data = personalizedStartDate.split("/");
			dataR = `${data[2]}-${data[1]}-${data[0]}`;
		}
		return (	
			dataR ??
			`${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`
		);
	};

	const getFinalDateFinal = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		let dataR = null;
		if(personalizedFinalDate){
			const data = personalizedFinalDate.split("/");
			dataR = `${data[2]}-${data[1]}-${data[0]}`;
		}
		return (
			dataR ??
			`${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`
		);
	};

	function setDataInico(e) {
		setPersonalizedStartDate(e);
	}
	function setDataFim(e) {
		setPersonalizedFinalDate(e);
	}

	function setTalhao(e) {
		const plot = allTalhoes.find((plotToCheck) => plotToCheck.id === e);
		if(plot)
			setAllSubdivisoes(plot.subdivisoes);
		setSelectedTalhao(e);
	}

	const getStartDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		return (
			personalizedStartDate ??
			`${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`
		);
	};

	const getFinalDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		return (
			personalizedFinalDate ??
			`${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`
		);
	};

	function setCultivo(cultivoId) {
		setSelectedCultivo(cultivoId);
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = async (index) => {
		if (index === "0") {
			return (window.location.href = `/pdf/?pag=Geral&index=${
				props.index
			}&talhaoId=${selectedTalhao}&parcelaId=${selectedSubdivisao}&culturaId=${selectedCultivo}&startDate=${getStartDate()}&finalDate=${getFinalDate()}`);
		}
		setAnchorEl(null);
	};

	function ExcelExportButton() {
		const handleExport = () => {
			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet("Relatorio geral");

			// Chame a função para obter os dados
			const data = dataGeralPDF();

			// Defina os cabeçalhos das colunas
			worksheet.columns = headerExcel;

			// Adicione os dados na planilha
			worksheet.addRows(data);

			// Crie o arquivo Excel
			workbook.xlsx.writeBuffer().then((buffer) => {
				const blob = new Blob([buffer], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				});
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = `RelatorioGeral${dataNow}.xlsx`;
				a.click();
			});
		};

		return (
			<MenuItem id={3} onClick={handleExport}>
				Exportar para Excel
			</MenuItem>
		);
	}

	function dataGeralPDF() {
		let newData = [];
		if(csvData) {
			if (csvData.propriedade) {
				newData.push(
					{
						coluna1: csvData?.propriedade?.nome ?? "Propriedade",
						coluna2: "",
						coluna3: "",
					},
					{
						coluna1: "Grupo de Serviços",
						coluna2: "Total de custo",
						coluna3: "Custo por hectare",
					}
				);

				Object.keys(csvData.propriedade.servicos.itens).forEach((key) => {
					const group = csvData.propriedade.servicos.itens[key];
					newData.push({
						coluna1: key,
						coluna2: Number(group.valor ?? 0),
						coluna3: Number(group.valorPorHectare ?? 0),
					});
				});

				newData.push(
					{
						coluna1: "Grupo de insumos",
						coluna2: "Total de custo",
						coluna3: "Custo por hectare",
					}
				);

				Object.keys(csvData.propriedade.insumos.itens).forEach((key) => {
					const group = csvData.propriedade.insumos.itens[key];
					newData.push({
						coluna1: key,
						coluna2: Number(group.valor ?? 0),
						coluna3: Number(group.valorPorHectare ?? 0),
					});
				});

				newData.push(
					{
						coluna1: "Total de custos:",
						coluna2: Number(csvData.propriedade.totalCustos ?? 0),
						coluna3: Number(csvData.propriedade.totalCustosPorHectare ?? 0),
					}
				);

				newData.push(
					{
						coluna1: "Produto",
						coluna2: "Total produzido",
						coluna3: "Produtividade",
					}
				);

				Object.keys(csvData.propriedade.cultivos.itens).forEach((key) => {
					const group = csvData.propriedade.cultivos.itens[key];
					newData.push({
						coluna1: key,
						coluna2: `${group.producao.toString().replace(".", ",")} ${csvData.propriedade.unidadeMedida}`,
						coluna3: `${group.produtividade.toString().replace(".", ",")} ${csvData.propriedade.unidadeMedida}`,
					});
				});

				newData.push(
					{
						coluna1: "Total de produção:",
						coluna2: `${csvData.propriedade.totalProducao.toString().replace(".", ",")} ${csvData.propriedade.unidadeMedida}`,
						coluna3: `${csvData.propriedade.totalProdutividade.toString().replace(".", ",")} ${csvData.propriedade.unidadeMedida}`,
					}
				);

				newData.push(
					{
						coluna1: "Custo por unidade de medida:",
						coluna2: `${convertNumberToMoney(csvData.propriedade.custoPorUnidade ?? 0)}/ ${csvData.propriedade.unidadeMedida}`,
						coluna3: "",
					}
				);
			}

			if(csvData.dados && csvData.dados.length > 0){
				csvData.dados.forEach((item) => {
					newData.push({
						coluna1: `Talhão ${item.nome}`,
						coluna2: "",
						coluna3: "",
					},
					{
						coluna1: "Grupo de Serviços",
						coluna2: "Total de custo",
						coluna3: "Custo por hectare",
					});

					Object.keys(item.servicos.itens).forEach((key) => {
						const group = item.servicos.itens[key];
						newData.push({
							coluna1: key,
							coluna2: Number(group.valor ?? 0),
							coluna3: Number(group.valorPorHectare ?? 0),
						});
					});

					newData.push(
						{
							coluna1: "Grupo de insumos",
							coluna2: "Total de custo",
							coluna3: "Custo por hectare",
						}
					);
	
					Object.keys(item.insumos.itens).forEach((key) => {
						const group = item.insumos.itens[key];
						newData.push({
							coluna1: key,
							coluna2: Number(group.valor ?? 0),
							coluna3: Number(group.valorPorHectare ?? 0),
						});
					});
	
					newData.push(
						{
							coluna1: "Total de custos:",
							coluna2: Number(item.totalCustos ?? 0),
							coluna3: Number(item.totalCustosPorHectare ?? 0),
						}
					);
	
					newData.push(
						{
							coluna1: "Produto",
							coluna2: "Total produzido",
							coluna3: "Produtividade",
						}
					);
	
					Object.keys(item.cultivos.itens).forEach((key) => {
						const group = item.cultivos.itens[key];
						newData.push({
							coluna1: key,
							coluna2: `${group.producao.toString().replace(".", ",")} ${csvData.propriedade.unidadeMedida}`,
							coluna3: `${group.produtividade.toString().replace(".", ",")} ${csvData.propriedade.unidadeMedida}`,
						});
					});
	
					newData.push(
						{
							coluna1: "Total de produção:",
							coluna2: `${item.totalProducao.toString().replace(".", ",")} ${csvData.propriedade.unidadeMedida}`,
							coluna3: `${item.totalProdutividade.toString().replace(".", ",")} ${csvData.propriedade.unidadeMedida}`,
						}
					);
	
					newData.push(
						{
							coluna1: "Custo por unidade de medida:",
							coluna2: `${convertNumberToMoney(item.custoPorUnidade ?? 0)}/ ${item.unidadeMedida}`,
							coluna3: "",
						}
					);
				});
			}
		}
		
		return newData;
	}

	const convertNumberToMoney = (value) => {
		return value.toLocaleString("pt-BR", {
			style: "currency",
			currency: "BRL",
		});
	};

	return (
		<Box component="main" sx={{ display: "flex", p: 0 }}>
			<Box sx={{ minWidth: "90vw", maxWidth: "95vw", p: 0 }}>
				<Box sx={{ width: "100%", p: 3, m: 0 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={{
							width: "100%",
							m: 0,
							p: 3,
							borderRadius: "8px",
							bgcolor: "white",
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 0, sm: 2, md: 3 }}
								sx={{
									p: 0,
									display: "flex",
									flexDirection: { xs: "column-reverse", sm: "row" },
								}}
							>
								<Grid item xs={12} sm={6} md={8}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Cultura: </p>
											<select
												onChange={(e) => setCultivo(e.target.value)}
												value={selectedCultivo}
												className={styles.Select}
											>
												<option value={-1}>Todos</option>
												{allCultivos.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.name}
														</option>
													);
												})}
											</select>
										</Grid>

										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Talhão:</p>
											<select
												onChange={(e) => setTalhao(e.target.value)}
												value={selectedTalhao}
												className={styles.Select}
											>
												<option value={-1}>Todos</option>
												{allTalhoes.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.nome}
														</option>
													);
												})}
											</select>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Parcela: </p>
											<select
												onChange={(e) => setSelectedSubdivisao(e.target.value)}
												value={selectedSubdivisao}
												className={styles.Select}
											>
												<option value={-1}>Todos</option>
												{allSubdivisoes.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.nome}
														</option>
													);
												})}
											</select>
										</Grid>

										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Data de início:</p>
											<InputData
												placeholder="Data de início"
												onDateChange={handleDateChangeInicio}
												classN={styles.Select}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Data de finalização:</p>
											<InputData
												classN={styles.Select}
												placeholder="Data de finalização"
												onDateChange={handleDateChangeFim}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={4}
									sx={{
										display: "flex",
										justifyContent: { sx: "start", sm: "end" },
										width: "100%",
										px: 1,
									}}
								>
									<Button
										id="basic-button"
										aria-controls={open ? "basic-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={open ? "true" : undefined}
										className={
											styles.ButtonExportChart
										}
										onClick={handleClick}
									>
										<IosShareIcon /> Exportar
										{open ? <ExpandLess /> : <ExpandMore />}
									</Button>
									<Menu
										id="basic-menu"
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										MenuListProps={{
											"aria-labelledby": "basic-button",
										}}
									>
										<MenuItem id={0} onClick={(e) => handleClose(e.target.id)}>
											PDF
										</MenuItem>
										{ExcelExportButton()}
									</Menu>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
}

export default RelatorioGeral;
