import axios from "axios";

export class LicenseRepository {

	constructor() {
		this.axios = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 30000,
		});
	}

	async update(licenseId, license) {
		try {
			const result = await this.axios.put(`license/${licenseId}`,
				license, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`
					}
				}
			);
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";
			throw new Error("Erro interno.");
		}
	}

	async save(license) {
		try {
			const result = await this.axios.post("license",
				license, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`
					}
				}
			);
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";
			throw new Error("Erro interno.");
		}
	}
}