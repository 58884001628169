//material ul imports
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import RegisterArea from "../../../components/RegistrationArea";
import TopMenuAdmin from "../../../components/TopMenuAdmin";
import styles from "./CultureCad.module.css";

import "./style.css";
import Loading from "../../../components/Loading";
import { GroupServiceService } from "../../../service/group_service_service";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function AdminGroupServiceCad() {
	const [searchParams] = useSearchParams();
	const [group, setGroup] = useState({packages:[]});
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [errorMessageName, setErrorMessageName] = useState(false);
	const [id, setId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const nameRef = useRef(null);

	const groupServiceService = new GroupServiceService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const groupServiceId = searchParams.get("id");

		if (groupServiceId !== null) {
			setId(groupServiceId);
			const groupServiceToEdit = await groupServiceService.getById(groupServiceId);
			setGroup(groupServiceToEdit);
		}
		setIsLoading(false);
	};

	const validationSave = async () => {
		if (group.nome && group.nome !== "") {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			setErrorMessageName(false);
			return true;
		} else {
			let message = "Um ou mais campos vazios. - (";
			if (!group.nome || group.nome === "") {
				message += "Nome, ";
				nameRef.current?.focus();
				setErrorMessageName(true);
			} else {
				setErrorMessageName(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};
	const handleSave = async () => {
		let groupToSave = {
			name: group.nome,
		};
		if (!id) {
			await groupServiceService.save(groupToSave);
		}else{
			groupToSave.id = group.id;
			await groupServiceService.update(groupToSave);
		}
	};
	
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenuAdmin open={["admin", "services"]}/>
				{/* href defini a volta  title defini o titulo da pagina*/}
				<Box component="main" sx={{ p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							sx={{ display: "flex", width: "100%" }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item>
									<RegisterArea
										title="Cadastro de Grupo"
										href="/admin/grupo-servicos"
										salveFunc={validationSave}
										typeMessage={typeMessage}
										message={message}
									>
										{/* inputs da pagina de resgitro */}
										<Grid
											container
											rowSpacing={1}
											columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										>
											<Grid
												item
												xs={12}
												sm={12}
												md={6}
												sx={{
													display: "flexbox",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<div
													className={
														errorMessageName
															? styles.errControl
															: styles.noErrControl
													}
												>	<	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,backgroundColor:"white",
																visibility:(group.nome && group.nome !== "" ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
																Grupo do serviço *
														</Typography>
		
														<p
															className={
																errorMessageName
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>
														<input 
															ref={nameRef}
															placeholder="Grupo do serviço *" 
															className={styles.selectInputPlaceholder} 
															value={group.nome} 
															onChange={(e)=>setGroup({...group, nome: e.target.value})}
														/>
													</Box>
												</div>
											</Grid>
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}

export default AdminGroupServiceCad;
