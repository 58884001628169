/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import LogoHorizontal from "../../../../Assets/images/logoHorizontal.png";

import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";

import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import { ReportService } from "../../../../service/report_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	Body: {
		paddingBottom: "15px",
	},
	page: {
		paddingBottom: "75px",
		paddingTop: "50px",
		paddingLeft: "50px",
		paddingRight: "50px",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	logo: {
		width: "100px",
		height: "100px",
		marginRight: "-20px",
	},
	textTitleDocument: {
		color: "#03989E",
		textTransform: "uppercase",
		fontSize: "24px",
		fontFamily: "Roboto bold",
	},
	line: {
		height: "3px",
		backgroundColor: "#E0DFDF",
	},
	containerSubHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: "10px",
	},
	containerTotal: {
		alignItems: "flex-end",
	},
	textBold: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textBoldFazenda: {
		fontSize: "18px",
		fontFamily: "Roboto bold",
	},
	textBoldPropriedade: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textBoldBig: {
		fontSize: "16px",
		marginBottom: "3px",
		fontFamily: "Roboto bold",
	},
	textNormal: {
		fontSize: "12px",
		textTransform: "uppercase",
	},
	body: {
		marginBottom: "30px",
	},
	mainTitle: {
		backgroundColor: "#03989E",
		padding: "3px 8px",
		textTransform: "uppercase",
		color: "white",
		borderRadius: "8px",
		letterSpacing: "1px",
		fontSize: "13px",
		marginBottom: "20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	content: {
		fontSize: "9px",
	},
	tableTitle: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
		marginBottom: "5px",
	},
	tableContent: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
	},
	larguraTexto: {
		width: "16.60%",
		paddingHorizontal: "3px",
		marginBottom: "4px",
	},
	containerTotalTalhao: {
		fontSize: "11px",
		paddingTop: "3px",
		paddingBottom: "5px",
		alignItems: "flex-end",
		textTransform: "uppercase",
	},
	containerTotalTalhaoGrey: {
		width: "100%",
		flexDirection: "row",
		backgroundColor: "#D9D9D9",
		paddingHorizontal: "15px",
		justifyContent: "space-between",
		padding: "7px",
		borderRadius: "8px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "70px",
		bottom: 0,
		paddingHorizontal: "50px",
	},
	dividerFooter: {
		border: "1px solid #03989E",
	},
	logoFooter: {
		width: "60px",
		height: "60px",
	},
	containerFooter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		display: "flex",
		flexDirection: "row",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "10px",
		borderRight: "1px solid #03989E",
		paddingHorizontal: "5px",
		color: "#03989E",
	},
	pagnumber: { color: "#03989E", paddingHorizontal: "5px" },
});

const PdfGeral = (props) => {
	const date = new Date().toLocaleDateString("pt-BR");
	const hora = new Date().toLocaleTimeString("pt-br", "h:mm A");
	const [user, setUser] = useState(null);
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);

	const culturaID = props.culturaId;
	const logo = props.logo;
	
	const reportService = new ReportService();

	useEffect(() => {
		initData();
	}, []);

	const convertNumberToMoney = (value) => {
		return value.toLocaleString("pt-BR", {
			style: "currency",
			currency: "BRL",
		});
	};

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const user = JSON.parse(localStorage.getItem("user"));
		setUser(user);

		const reportProps = {
			propertyFarm: String(culturaID) !== "-1" ? culturaID : null,
			plot: String(props.talhaoId) !== "-1" ? props.talhaoId : null,
			subdivision: String(props.parcelaId) !== "-1" ? props.parcelaId : null,
			property: propertyId,
			startDate: new Date(props.startDate),
			finalDate: new Date(props.finalDate)
		};
		const generalData = await reportService.getGeneralData(reportProps);

		setData(generalData);
		setLoading(false);
	};

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	return (
		!loading ?
			<Document>
				<Page size="A4" style={styles.page}>
					<View style={styles.header} fixed>
						<View>
							<Text style={styles.textTitleDocument}>Relatório Geral</Text>
							<Text style={styles.textBoldFazenda}>
								{data.empresa?.nome ?? "Não informado"}
							</Text>
							<Text style={styles.textBoldPropriedade}>
								{data.propriedade?.nome ?? "Não informado"}
							</Text>
						</View>
						{logo !== null &&
							<Image style={styles.logo} src={logo} />}
					</View>

					<View style={styles.line} fixed></View>

					<View style={styles.containerSubHeader}>
						<View>
							<Text style={styles.textBold}>Cultivo:</Text>
							<Text style={styles.textNormal}>
								{data.produto?.nome ?? "Todos"}
							</Text>
							<Text style={styles.textBold}>Área:</Text>
							<Text style={styles.textNormal}>
								{data.area?.nome ?? "Todos"}
							</Text>
							<Text style={styles.textBold}>Período:</Text>
							<Text style={styles.textNormal}>
								{formatDate(props.startDate)} a{" "}
								{formatDate(props.finalDate)}
							</Text>
						</View>
					</View>

					<View style={styles.Body}>
						<View style={styles.mainTitle}>
							<Text>{data.propriedade?.nome ?? "Não informado"}</Text>
						</View>
						<View style={styles.content}>
							<View style={styles.containerTotalTalhao} wrap={false}>
								<View style={styles.containerTotalTalhaoGrey}>
									<View>
										<Text>Grupo</Text>
										<Text>Serviços</Text>
									</View>
									<View>
										<Text>Total de custo</Text>
										<Text>{convertNumberToMoney(data.propriedade.totalCustosServico ?? 0)}</Text>
									</View>
									<View>
										<Text>Custo por hectare</Text>
										<Text>{convertNumberToMoney(data.propriedade.totalCustosServicoHectares ?? 0)}</Text>
									</View>
								</View>
							</View>

							{Object.keys(data.propriedade.servicos.itens).map((key, index) => {
								return (
									<View key={index} style={styles.tableContent} wrap={false}>
										<Text style={styles.larguraTexto}>{key}</Text>
										<Text style={styles.larguraTexto}>{convertNumberToMoney(data.propriedade.servicos.itens[key].valor ?? 0)}</Text>
										<Text style={styles.larguraTexto}>{convertNumberToMoney(data.propriedade.servicos.itens[key].valorPorHectare ?? 0)}</Text>
									</View>
								);
							})}

							<View style={styles.containerTotalTalhao} wrap={false}>
								<View style={styles.containerTotalTalhaoGrey}>
									<View>
										<Text>Grupo</Text>
										<Text>Insumos</Text>
									</View>
									<View>
										<Text>Total de custo</Text>
										<Text>{convertNumberToMoney(data.propriedade.totalCustosInsumo ?? 0)}</Text>
									</View>
									<View>
										<Text>Custo por hectare</Text>
										<Text>{convertNumberToMoney(data.propriedade.totalCustosInsumoHectares ?? 0)}</Text>
									</View>
								</View>
							</View>

							{Object.keys(data.propriedade.insumos.itens).map((key, index) => {
								return (
									<View key={index} style={styles.tableContent} wrap={false}>
										<Text style={styles.larguraTexto}>{key}</Text>
										<Text style={styles.larguraTexto}>{convertNumberToMoney(data.propriedade.insumos.itens[key].valor ?? 0)}</Text>
										<Text style={styles.larguraTexto}>{convertNumberToMoney(data.propriedade.insumos.itens[key].valorPorHectare ?? 0)}</Text>
									</View>
								);
							})}

							<View style={styles.containerTotalTalhao} wrap={false}>
								<View style={styles.containerTotalTalhaoGrey}>
									<Text>Total de custos:</Text>
									<Text>{convertNumberToMoney(data.propriedade.totalCustos ?? 0)}</Text>
									<Text>{convertNumberToMoney(data.propriedade.totalCustosPorHectare ?? 0)}</Text>
								</View>
							</View>

							<View style={styles.tableTitle}>
								<Text style={styles.larguraTexto}>Produto</Text>
								<Text style={styles.larguraTexto}>Total produzido</Text>
								<Text style={styles.larguraTexto}>Produtiviodade</Text>
							</View>

							{Object.keys(data.propriedade.cultivos.itens).map((key, index) => {
								return (
									<View key={index} style={styles.tableContent} wrap={false}>
										<Text style={styles.larguraTexto}>{key}</Text>
										<Text style={styles.larguraTexto}>{data.propriedade.cultivos.itens[key].producao.toString().replace(".", ",")} {data.propriedade.unidadeMedida}</Text>
										<Text style={styles.larguraTexto}>{data.propriedade.cultivos.itens[key].produtividade.toString().replace(".", ",")} {data.propriedade.unidadeMedida}/ ha</Text>
									</View>
								);
							})}

							<View style={styles.containerTotalTalhao} wrap={false}>
								<View style={styles.containerTotalTalhaoGrey}>
									<Text>Total de Produção:</Text>
									<Text>{data.propriedade.totalProducao.toString().replace(".", ",")} {data.propriedade.unidadeMedida}</Text>
									<Text>{data.propriedade.totalProdutividade.toString().replace(".", ",")} {data.propriedade.unidadeMedida}/ ha</Text>
								</View>
							</View>

							<View style={{...styles.containerTotalTalhao, paddingTop: "0px"}} wrap={false}>
								<View style={styles.containerTotalTalhaoGrey}>
									<Text>Custo por unidade de medida:</Text>
									<Text>{convertNumberToMoney(data.propriedade.custoPorUnidade ?? 0)}/ {data.propriedade.unidadeMedida}</Text>
								</View>
							</View>
						</View>

						{data.dados.map((item, index) => {
							return (
								<View key={index}>
									<View style={styles.mainTitle}>
										<Text>{`Talhão ${item.nome}` ?? "Não informado"}</Text>
									</View>
									<View style={styles.content}>
										<View style={styles.containerTotalTalhao} wrap={false}>
											<View style={styles.containerTotalTalhaoGrey}>
												<View>
													<Text>Grupo</Text>
													<Text>Serviços</Text>
												</View>
												<View>
													<Text>Total de custo</Text>
													<Text>{convertNumberToMoney(item.totalCustosServico ?? 0)}</Text>
												</View>
												<View>
													<Text>Custo por hectare</Text>
													<Text>{convertNumberToMoney(item.totalCustosServicoHectares ?? 0)}</Text>
												</View>
											</View>
										</View>

										{Object.keys(item.servicos.itens).map((key, index) => {
											return (
												<View key={index} style={styles.tableContent} wrap={false}>
													<Text style={styles.larguraTexto}>{key}</Text>
													<Text style={styles.larguraTexto}>{convertNumberToMoney(item.servicos.itens[key].valor ?? 0)}</Text>
													<Text style={styles.larguraTexto}>{convertNumberToMoney(item.servicos.itens[key].valorPorHectare ?? 0)}</Text>
												</View>
											);
										})}

										<View style={styles.containerTotalTalhao} wrap={false}>
											<View style={styles.containerTotalTalhaoGrey}>
												<View>
													<Text>Grupo</Text>
													<Text>Insumos</Text>
												</View>
												<View>
													<Text>Total de custo</Text>
													<Text>{convertNumberToMoney(item.totalCustosInsumo ?? 0)}</Text>
												</View>
												<View>
													<Text>Custo por hectare</Text>
													<Text>{convertNumberToMoney(item.totalCustosInsumoHectares ?? 0)}</Text>
												</View>
											</View>
										</View>

										{Object.keys(item.insumos.itens).map((key, index) => {
											return (
												<View key={index} style={styles.tableContent} wrap={false}>
													<Text style={styles.larguraTexto}>{key}</Text>
													<Text style={styles.larguraTexto}>{convertNumberToMoney(item.insumos.itens[key].valor ?? 0)}</Text>
													<Text style={styles.larguraTexto}>{convertNumberToMoney(item.insumos.itens[key].valorPorHectare ?? 0)}</Text>
												</View>
											);
										})}

										<View style={styles.containerTotalTalhao} wrap={false}>
											<View style={styles.containerTotalTalhaoGrey}>
												<Text>Total de custos:</Text>
												<Text>{convertNumberToMoney(item.totalCustos ?? 0)}</Text>
												<Text>{convertNumberToMoney(item.totalCustosPorHectare ?? 0)}</Text>
											</View>
										</View>

										<View style={styles.tableTitle}>
											<Text style={styles.larguraTexto}>Produto</Text>
											<Text style={styles.larguraTexto}>Total produzido</Text>
											<Text style={styles.larguraTexto}>Produtiviodade</Text>
										</View>

										{Object.keys(item.cultivos.itens).map((key, index) => {
											return (
												<View key={index} style={styles.tableContent} wrap={false}>
													<Text style={styles.larguraTexto}>{key}</Text>
													<Text style={styles.larguraTexto}>{item.cultivos.itens[key].producao.toString().replace(".", ",")} {item.unidadeMedida}</Text>
													<Text style={styles.larguraTexto}>{item.cultivos.itens[key].produtividade.toString().replace(".", ",")} {item.unidadeMedida}/ ha</Text>
												</View>
											);
										})}

										<View style={styles.containerTotalTalhao} wrap={false}>
											<View style={styles.containerTotalTalhaoGrey}>
												<Text>Total de Produção:</Text>
												<Text>{item.totalProducao.toString().replace(".", ",")} {item.unidadeMedida}</Text>
												<Text>{item.totalProdutividade.toString().replace(".", ",")} {item.unidadeMedida}/ ha</Text>
											</View>
										</View>

										<View style={{...styles.containerTotalTalhao, paddingTop: "0px"}} wrap={false}>
											<View style={styles.containerTotalTalhaoGrey}>
												<Text>Custo por unidade de medida:</Text>
												<Text>{convertNumberToMoney(item.custoPorUnidade ?? 0)}/ {item.unidadeMedida}</Text>
											</View>
										</View>
									</View>
								</View>
							);
						})}
					</View>
					<View style={styles.footer} fixed>
						<View style={styles.dividerFooter}></View>
						<View style={styles.containerFooter}>
							<Image src={LogoHorizontal} style={styles.logoFooter} />
							<View style={styles.paggingContainer}>
								<View style={styles.paggingText}>
									<Text style={{ fontFamily: "Roboto bold" }}>
										https://sisagri.com.br
									</Text>
									<Text>
										{date + " - " + hora + " - " + (user?.name ?? "Não informado")}
									</Text>
								</View>
								<Text
									style={styles.pagnumber}
									render={({ pageNumber }) => `${pageNumber}`}
								/>
							</View>
						</View>
					</View>
				</Page>
			</Document>
			: <Document></Document>
	);
};

export default PdfGeral;
