import React, { useEffect, useState } from "react";
import Chart from "../../Chart";
// import "./style.css";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import ButtonExportChart from "../../ButtonExportChart";
import styles from "./Produtividade.module.css";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";
import InputData from "../../InputData";
import { ReportService } from "../../../service/report_service";
import { FarmService } from "../../../service/farm_service";
import { PropertyService } from "../../../service/property_service";

const month = [
	"JANEIRO",
	"FEVEREIRO",
	"MARÇO",
	"ABRIL",
	"MAIO",
	"JUNHO",
	"JULHO",
	"AGOSTO",
	"SETEMBRO",
	"OUTUBRO",
	"NOVEMBRO",
	"DEZEMBRO",
];

const subdivisionCategories = {
	PARCELA: "Parcela",
	SETOR: "Setor",
	CANTEIRO: "Canteiro",
	BANCADA: "Bancada",
	BALCAO: "Balcão",
	BLOCO: "Bloco",
	GLEBA: "Gleba",
	QUADRA: "Quadra",
	LOTE: "Lote",
};

function Produtividade(props) {
	const [personalizedStartDate, setPersonalizedStartDate] = useState(null);
	const [personalizedFinalDate, setPersonalizedFinalDate] = useState(null);
	const [allCultivos, setAllCultivos] = useState([]);
	const [selectedCultivo, setSelectedCultivo] = useState(-1);
	const [allTalhoes, setAllTalhoes] = useState([]);
	const [selectedTalhao, setSelectedTalhao] = useState(-1);
	const [allSubdivisoes, setAllSubdivisoes] = useState([]);
	const [selectedSubdivisao, setSelectedSubdivisao] = useState(-1);
	const [data, setData] = useState([["MESES", "CULTIVO"], ["N/A", 0]]);
	const [disable, setDisable] = useState(true);
	const [selectedDateInicio, setSelectedDateInicio] = useState("");
	const [selectedDateFim, setSelectedDateFim] = useState("");

	const reportService = new ReportService();
	const farmService = new FarmService();
	const propertyService = new PropertyService();

	const handleDateChangeInicio = (date) => {
		setSelectedDateInicio(new Date(date).toLocaleDateString("pt-BR"));
		setDataInico(new Date(date).toLocaleDateString("pt-BR"));
	};

	const handleDateChangeFim = (date) => {
		setSelectedDateFim(new Date(date).toLocaleDateString("pt-BR"));
		setDataFim(new Date(date).toLocaleDateString("pt-BR"));
	};

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {

		const propertyId = localStorage.getItem("propertyId");

		const farmParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					cultivo: {
						cultura: "asc"
					}
				},
				{
					cultivo: {
						variedade: "asc"
					}
				}
			])
		};

		const farmsInProperty = await farmService.get(farmParams, propertyId);

		setAllCultivos(farmsInProperty.farms);
		setSelectedCultivo(0);

		const plotsInProperty = await propertyService.getPlots(propertyId);

		const plots = plotsInProperty.map((plot) => {
			return {...plot, subdivisoes: getSubdivisions(plot.subdivisoes)};
		});

		setAllTalhoes(plots);
		setSelectedTalhao(-1);
	};

	const getSubdivisions = (subdivisions) => {
		if(!subdivisions || subdivisions.length === 0)
			return [];
		
		let disponibleSubdivisions = [];

		subdivisions.forEach((subdivision) => {
			const disponibleChildren = getSubdivisions(subdivision.subdivisoes);

			disponibleChildren.forEach(child => {
				child = {
					nome: `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento} - ${child.nome}`,
					id: child.id
				};
				disponibleSubdivisions.push(child);
			});
			
			subdivision = {
				nome: `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento}`,
				id: subdivision.id
			};
			disponibleSubdivisions.push(subdivision);
		});

		return disponibleSubdivisions;
	};

	useEffect(() => {
		if (
			allCultivos.length > 0 &&
			allTalhoes.length > 0 &&
			selectedDateInicio &&
			selectedDateFim
		) {
			const currentDate = new Date();
			const currentMonth = currentDate.getMonth() + 1;
			const startDate =
				selectedDateInicio ??
				`${currentDate.getFullYear()}-${currentMonth
					.toString()
					.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
			const finalDate =
				selectedDateFim ??
				`${currentDate.getFullYear()}-${currentMonth
					.toString()
					.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
					
			if(selectedCultivo && selectedCultivo.toString() !== "-1")
				setDataForPeriod(getFormatDate(startDate), getFormatDate(finalDate));
		}
	}, [
		selectedTalhao,
		selectedCultivo,
		selectedSubdivisao,
		selectedDateInicio,
		selectedDateFim,
		personalizedFinalDate,
		personalizedStartDate,
	]);

	const setDataForPeriod = async (startDate, finalDate) => {
		const farm = allCultivos.find((farmToCheck) => (farmToCheck.id === selectedCultivo));

		const params = {
			propertyFarm: farm.id,
			plot: String(selectedTalhao) !== "-1" ? selectedTalhao : null,
			subdivision: String(selectedSubdivisao) !== "-1" ? selectedSubdivisao : null,
			startDate: startDate,
			finalDate: finalDate
		};

		const reportData = await reportService.getProductivityGraphicData(params);

		const farmName = `${farm.cultivo.cultura} ${farm.cultivo.variedade ?? ""}`;

		let newData = [["MESES", farmName.toUpperCase()]];

		const startMonthIndex = Number(startDate.split("-")[1]) - 1;
		const finalMonthIndex = Number(finalDate.split("-")[1]) - 1;
		let currentMonthIndex = startMonthIndex;
		
		while (currentMonthIndex <= finalMonthIndex) {
			let colheitaQuantity = 0;
			for (let i = 0; i < reportData.length; i++) {
				const harvest = reportData[i];
				if (
					harvest.lote.dataColheita &&
					harvest.lote.dataColheita !== "" &&
					new Date(harvest.lote.dataColheita).getMonth() === currentMonthIndex
				)
					colheitaQuantity++;
			}

			const monthName = month[currentMonthIndex];
			newData.push([monthName, colheitaQuantity]);
			currentMonthIndex++;
		}
		if (newData.length === 1) newData.push(["SEM DADOS", 0]);
		
		setData(newData);
	};

	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};

	function setTalhao(e) {
		const plot = allTalhoes.find((plotToCheck) => plotToCheck.id === e);
		if(plot)
			setAllSubdivisoes(plot.subdivisoes);
		setSelectedTalhao(e);
	}
	function setCultura(e) {
		setSelectedCultivo(e);
		if (selectedDateInicio !== "" && selectedDateFim !== "" && e !== -1)
			setDisable(false);
		else setDisable(true);
	}
	function setDataInico(e) {
		setPersonalizedStartDate(e);
		if (e !== "" && selectedDateFim !== "" && selectedCultivo !== "-1")
			setDisable(false);
		else setDisable(true);
	}
	function setDataFim(e) {
		setPersonalizedFinalDate(e);
		if (selectedDateInicio !== "" && e !== "" && selectedCultivo !== "-1")
			setDisable(false);
		else setDisable(true);
	}

	const getStartDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		return (
			personalizedStartDate ??
			`${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`
		);
	};

	const getFinalDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		return (
			personalizedFinalDate ??
			`${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`
		);
	};
	return (
		<Box component="main" sx={{ display: "flex", p: 0 }}>
			<Box sx={{ minWidth: "90vw", maxWidth: "95vw", p: 0 }}>
				<Box sx={{ width: "100%", p: 3, m: 0 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={{
							width: "100%",
							m: 0,
							p: 3,
							borderRadius: "8px",
							bgcolor: "white",
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 0, sm: 2, md: 3 }}
								sx={{
									p: 0,
									display: "flex",
									flexDirection: { xs: "column-reverse", sm: "row" },
								}}
							>
								<Grid item xs={12} sm={8} md={8}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Cultivo: </p>
											<select
												onChange={(e) => setCultura(e.target.value)}
												value={selectedCultivo}
												className={styles.Select}
											>
												<option value={-1}>cultivo</option>
												{allCultivos.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{captalizeFirstLetterEacheWord(item.cultivo.cultura)} {captalizeFirstLetterEacheWord(item.cultivo.variedade ?? "")}
														</option>
													);
												})}
											</select>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Talhão:</p>
											<select
												onChange={(e) => setTalhao(e.target.value)}
												value={selectedTalhao}
												className={styles.Select}
											>
												<option value={-1}>Todos</option>
												{allTalhoes.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.nome}
														</option>
													);
												})}
											</select>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Parcela: </p>
											<select
												onChange={(e) => setSelectedSubdivisao(e.target.value)}
												value={selectedSubdivisao}
												className={styles.Select}
											>
												<option value={-1}>Todos</option>
												{allSubdivisoes.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.nome}
														</option>
													);
												})}
											</select>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Data de início: </p>
											<InputData
												placeholder="Data de início"
												onDateChange={handleDateChangeInicio}
												classN={styles.Select}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Data de finalização: </p>
											<InputData
												classN={styles.Select}
												placeholder="Data de finalização"
												onDateChange={handleDateChangeFim}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={4}
									sx={{
										display: "flex",
										justifyContent: { sx: "start", sm: "end" },
										width: "100%",
										px: 1,
									}}
								>
									<ButtonExportChart
										disabled={disable}
										href={`/pdf/?pag=Produtividades&index=${
											props.index
										}&talhaoId=${selectedTalhao}&parcelaId=${selectedSubdivisao}&culturaId=${selectedCultivo}&startDate=${getStartDate()}&finalDate=${getFinalDate()}`}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								display: "flex",
								alignItems: "stretch",
								width: "100%",
								minHeight: "50vh",
							}}
						>
							<Chart data={data} options={props.options} chartType="LineChart" />
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
}

export default Produtividade;
