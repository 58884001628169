import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import * as I from "../../../IconsImport";
import AsideMenuListItem from "../asideMenuListItem/asideMenuListItem";

//import css modules
import { useState } from "react";
import styles from "./AsideMenuList.module.css";

export default function AsideMenuListAdmin(props) {
	const [open, setOpen] = useState(props.open[0]);
	
	const handleClick = (newOpen) => {
		if (open !== newOpen)
			setOpen(newOpen); //se o item estiver aberto, fecha, se não, Abreve
		else setOpen("");
	};
	//determina o subItem que ira ficar sob o focus
	const setClassItemFocus = (pag) => {
		if (props.open[1] === pag) return true; //se o subItem estiver aberto, retorna true
	};

	const listStyle = {
		width: "100%",
		// padding: "0px 10px",
		bgcolor: "#00A69A",
		color: "white",
	};

	return (
		<List
			sx={listStyle}
			component="nav"
			className={styles.AsideMenuListDrop}
			aria-labelledby="nested-list-subheader"
		>
			{/*Admin */}
			<div className={styles.containerSublist}>
				<ListItemButton
					className={styles.containerSublistDrop}
					onClick={() => handleClick("admin")}
				>
					<ListItemText primary="Administração" />
					{open === "admin" ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={open === "admin"} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("business")}
							href="/admin/empresas"
							icon={<I.IconProperty />}
							text="Empresa"
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("farms")}
							href="/admin/culturas"
							icon={<I.IconUsers />}
							text="Culturas"
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("services")}
							href="/admin/grupo-servicos"
							icon={<I.IconServices />}
							text="Serviços"
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("inputs")}
							href="/admin/grupo-insumos"
							icon={<I.IconInsumo />}
							text="Insumos"
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("incidents")}
							href="/admin/ocorrencias"
							icon={<I.IconOccurrences />}
							text="Ocorrências"
						/>
					</List>
				</Collapse>
			</div>
		</List>
	);
}
