import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import TopMenuAdmin from "../../../../components/TopMenuAdmin";
import styles from "./serviceCad.module.css";

//material ul imports
import { Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//components
import RegisterArea from "../../../../components/RegistrationArea";
import Loading from "../../../../components/Loading";
import { GroupInputService } from "../../../../service/group_input_service";

function AdminInputCad() {
	const { groupId } = useParams();

	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const [inputInGroup, setInputInGroup] = useState({});
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [errorMessageName, setErrorMessageName] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [groups, setGroups] = useState([]);

	const nameRef = useRef(null);

	const groupInputService = new GroupInputService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		if(id){
			const input = await groupInputService.getInputById(id);
			setInputInGroup(input);

			const allGroups = await getGroupsForInput(input);
			setGroups(allGroups);
		} else {
			const allGroups = await getAllGroups();
			setGroups(allGroups);
		}
		setIsLoading(false);
	};

	const getAllGroups = async () => {
		const allInputGroups = await groupInputService.get();
		const allGroups = allInputGroups.map((group, i) => {
			const selected = groupId === group.id;
			return {
				id: i,
				groupId: group.id,
				nome: group.nome,
				selected: selected,
				changed: selected,
			};
		});
		return allGroups;
	};

	const getGroupsForInput = async (input) => {
		const allGroups = [];
		const allInputGroups = await groupInputService.get();

		for (let i = 0; i < allInputGroups.length; i++) {
			const inputGroup = allInputGroups[i];
			const inputGroupIndex = input.gruposInsumo.findIndex((group) => group.grupoInsumo.id === inputGroup.id);
			allGroups.push({
				id: i,
				groupId: inputGroup.id,
				nome: inputGroup.nome,
				selected: inputGroupIndex >= 0,
				changed: false,
			});
		}
		return allGroups;
	};

	const validationSave = async () => {
		if (inputInGroup.nome && groups.some((group) => group.selected)) {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			setErrorMessageName(false);
			return true;
		} else {
			let message = "Um ou mais campos vazios. - (";
			if (!inputInGroup.nome) {
				message += "Nome, ";
				nameRef.current.focus();
				setErrorMessageName(true);
			} else {
				setErrorMessageName(false);
			}
			if (!groups.some((group) => group.selected))
				message += "Grupo, ";
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};

	const handleSave = async () => {
		const groupsToSave = groups.filter((group) => group.changed && group.selected);
		const groupsToSaveId = groupsToSave.map((group) => group.groupId);

		let inputToSave = {
			name: inputInGroup.nome,
			includeGroups: groupsToSaveId,
		};

		if (!id){
			await groupInputService.saveInput(inputToSave);
		} else {
			const groupsToRemove = groups.filter((group) => group.changed && !group.selected);
			const groupsToRemoveId = groupsToRemove.map((group) => group.groupId);
			
			inputToSave.removeGroups = groupsToRemoveId;
			inputToSave.id = id;
			await groupInputService.updateInput(inputToSave);
		}
	};

	const handleGroupChangeAll = (e) => {
		const { name, checked } = e.target;
		if (name === "allSelect") {
			let tempGroup = groups.map((group) => {
				let newGroup = group;
				newGroup.selected = checked;
				newGroup.changed = checked;
				return newGroup;
			});
			setGroups(tempGroup);
		}
	};

	const handleGroupChange = (item) => {
		const newGroups = groups.slice();
		const group = groups[item.id];
		group.selected = !group.selected;
		group.changed = !group.changed;
		newGroups[item.id] = group;
		setGroups(newGroups);
	};

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenuAdmin
					open={["admin", "inputs"]}
				/>
				<Box component="main" sx={{ p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							sx={{ display: "flex" }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item>
									<RegisterArea
										title={
											id
												? "Editar insumo"
												: "Cadastro de insumo"
										}
										href={`/admin/grupo-insumos/${groupId}/insumos`}
										salveFunc={validationSave}
										typeMessage={typeMessage}
										message={message}
									>
										{/* inputs da pagina de resgitro */}
										<Grid
											container
											rowSpacing={1}
											columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										>
											<Grid item xs={12} sm={12} md={6}>
												<div
													className={
														errorMessageName
															? styles.errControl
															: styles.noErrControl
													}
												>
													<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(inputInGroup.nome ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															Nome *
														</Typography>
														<p
															className={
																errorMessageName
																	? styles.errText
																	: styles.noErrText
															}
														>
															*
														</p>

														<input
															ref={nameRef}
															value={inputInGroup.nome}
															onChange={e => setInputInGroup({...inputInGroup, nome: e.target.value})}
															type="text"
															placeholder="Nome *"
															id="name"
														/>
													</Box>
												</div>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item
													sx={{
														// border: "1px solid #b1b1b1",
														boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
														display: "flex",
														flexDirection: "column",
														alignItems: "flex-start",
														height: "100%",
													}}
												>
													<p className={styles.labelTitle}>
														<b>Grupos *</b>
													</p>
													<div className={styles.checkboxContainer}>
														<input
															type="checkbox"
															className={styles.inputCheckbox}
															name="allSelect"
															id="grupo"
															checked={
																!groups.some(
																	(group) => group.selected !== true
																)
															}
															onChange={(e) => handleGroupChangeAll(e)}
														/>
														<label
															htmlFor="grupo"
															className={styles.labelTitle}
														>
															<b>
																Cadastre o insumo nos grupos
															</b>
														</label>
													</div>
													<Grid
														container
														rowSpacing={1}
														columnSpacing={{ xs: 1, sm: 2, md: 3 }}
													>
														{groups.map((group, index) => (
															<Grid
																item
																xs={"auto"}
																key={index}
																sx={{ minWidth: "250px" }}
															>
																<Item
																	htmlFor={group.id}
																	sx={{
																		display: "flex",
																		justifyContent: "start",
																		p: 0,
																		m: 0,
																	}}
																>
																	<div className={styles.checkboxContainer}>
																		<input
																			type="checkbox"
																			className={styles.inputCheckbox}
																			id={group.id}
																			name={group.nome}
																			checked={group.selected}
																			onChange={() =>
																				handleGroupChange(group)
																			}
																		/>
																		<label
																			htmlFor={group.id}
																			className={styles.CheckPropriedadeLabel}
																		>
																			{group.nome}
																		</label>
																	</div>
																</Item>
															</Grid>
														))}
													</Grid>
												</Item>
											</Grid>
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}
export default AdminInputCad;
