import React, { useEffect, useState } from "react";

import Loading from "./components/Loading";

const AdminRoute = ({ children }) => {
	let [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		if(user.sisagri){
			setIsAuthenticated(true);
		}else{
			alert("Usuário não é administrador.");
			window.location.href = "/home"; 	
		}
	};
	return isAuthenticated ? (
		<div>{children}</div>
	) : (
		<div>
			<Loading /> {/* <p>loading...</p> criar um load */}
		</div>
	);
};

export default AdminRoute;
