import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import {
	ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";

export default function UserComponentAdmin() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [user, setUser] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	React.useEffect(() => {
		getAndSetCurrentUser();
	}, []);

	const getAndSetCurrentUser = async () => {
		const userData = JSON.parse(localStorage.getItem("user"));
		setUser(userData);
	};

	return (
		<div>
			<Button
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				sx={{
					bgcolor: "#00A69A",
					color: "white",
					width: "auto",
					maxHeight: "50px",
					borderRadius: "8px",
					display: "flex",
					m: 0,
				}}
			>
				<IconButton
					onClick={handleClick}
					size="small"
					aria-controls={open ? "account-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
				>
					<Avatar sx={{ width: 32, height: 32 }}>
						{user?.name?.substring(0, 1) ?? "N"}
					</Avatar>
				</IconButton>
				<Box
					sx={{
						display: { xs: "none", sm: "flex" },
						flexDirection: "column",
						alignItems: "start",
						justifyContent: "center",
						p: 0,
						m: 0,
					}}
				>
					{user?.name ?? "Não definido"}
				</Box>
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				sx={{
					p: 0,
					m: 0,
				}}
			>

				<MenuItem
					sx={{
						color: "gray",
						display: "flex",
						m: 0,
					}}
					onClick={() => {
						localStorage.clear();
						window.location.href = "/admin";
					}}
				>
					<ExitToAppIcon />
					<Typography sx={{ textTransform: "none", pl: 1 }}>
						Sair da conta
					</Typography>
				</MenuItem>
			</Menu>
		</div>
	);
}
