import axios from "axios";

export class BusinessRepository {

	constructor() {
		this.axios = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 30000,
		});
	}

	async get(params) {
		try {
			const result = await this.axios.get("business", {
				params: params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";
			throw new Error("Erro interno.");
		}
	}

	async getById(businessId) {
		try {
			const result = await this.axios.get(`business/${businessId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";
			throw new Error("Erro interno.");
		}
	}

	async update(businessId, business) {
		try {
			const result = await this.axios.put(`business/${businessId}`,
				business, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`
					}
				}
			);
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";
			throw new Error("Erro interno.");
		}
	}

	async save(business) {
		try {
			const result = await this.axios.post("business",
				business, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`
					}
				}
			);
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";
			throw new Error("Erro interno.");
		}
	}

	async changeVisibility(id) {
		try {
			const result = await this.axios.put(`business/visibility/${id}`, {},  {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}
}