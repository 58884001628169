import React, { useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";
import TopMenuAdmin from "../../components/TopMenuAdmin";
import "../CommonStyle/style.css";

//import components
import Search from "../../components/SearchHeader";
import Table from "../../components/TableGenerator";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { captalizeFirstLetterEacheWord } from "../../utils/utils";
import Loading from "../../components/Loading";
import { CultivationService } from "../../service/cultivation_service";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//column
const columns =[
	{
		id: "culture",
		label: "Cultura",
	},
	{
		id: "variety",
		label: "Variedade",
	},
	{
		id: "createdAt",
		label: "Data de criação",
	},

];

function AdminFarm() {
	const [search, setSearch] = useState("");
	const [farms, setFarms] = useState([]);
	const [totalFarmsData, setTotalFarmsData] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const cultivationService = new CultivationService();

	const updateData = async (page, rowsPerPage) => {
		const farmParams = {
			page: page + 1,
			limit: rowsPerPage,
			filterValue: {},
			sortBy: JSON.stringify([
				{
					cultura: "asc"
				},
				{
					variedade: "asc"
				}
			])
		};

		const allFarms = await cultivationService.get(farmParams);
		const farmsToShow = allFarms.cultivations.map((farm) => {
			return {
				id: farm.id,
				culture: captalizeFirstLetterEacheWord(farm.cultura),
				variety: captalizeFirstLetterEacheWord(farm.variedade ?? " - "),
				createdAt: new Date(farm.createdAt).toLocaleDateString("pt-BR")
			};
		});
		setFarms(farmsToShow);
		setTotalFarmsData(allFarms.totalItems);
		setIsLoading(false);
	};

	const dataFilter = farms.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	function handleClick(e) {
		setSearch(e.target.value);
	}

	const handleDelete = async (id) => {
		await cultivationService.changeVisibility(id);
	};

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenuAdmin open={["admin", "farms"]}/>
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Search
										title="Culturas"
										href="/admin/culturas/cadastro"
										textButton="Nova Cultura"
									>
										<InputSearch func={handleClick} />
									</Search>
								</Item>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Table
										listSize={totalFarmsData}
										data={dataFilter}
										updateData={updateData}
										columns={columns}
										handleDelete={handleDelete}
										editHref="/admin/culturas/cadastro?id="
										// onclickRow={"/culturas/Embalagens"}
									/>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}

export default AdminFarm;
