import { GroupInputRepository } from "../repository/group_input_repository";

export class GroupInputService {

	constructor() {
		this.groupInputRepository = new GroupInputRepository();
	}

	async get() {
		return await this.groupInputRepository.get();
	}

	async getInputs() {
		return await this.groupInputRepository.getInputs();
	}

	async getInputByGroup(groupId) {
		return await this.groupInputRepository.getInputByGroup(groupId);
	}

	async save(groupInput) {
		const savedGroupInput = await this.groupInputRepository.save(groupInput);
		return savedGroupInput;
	}

	async update(groupInput) {
		const savedGroupInput = await this.groupInputRepository.update(groupInput);
		return savedGroupInput;
	}

	async getById(id) {
		const cultivation = await this.groupInputRepository.getById(id);
		return cultivation;
	}

	async saveInput(input) {
		const savedService = await this.groupInputRepository.saveInput(input);
		return savedService;
	}

	async updateInput(input) {
		const savedInput = await this.groupInputRepository.updateInput(input);
		return savedInput;
	}

	async getInputById(id) {
		const input = await this.groupInputRepository.getInputById(id);
		return input;
	}

	async changeVisibility(id) {
		await this.groupInputRepository.changeVisibility(id);
	}

	async changeInputVisibility(id) {
		await this.groupInputRepository.changeInputVisibility(id);
	}
}