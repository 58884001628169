import React from "react";

//import css module
import styles from "./AsideMenu.module.css";
import Logo from "../../../Assets/images/logoMenu.svg";
import AsideMenuListAdmin from "./AsideMenuList/AsideMenuListAdmin";
import Divider from "@mui/material/Divider";

function AsideMenuAdmin(props) {
	const [isLoaded, setIsLoaded] = React.useState(false);
	return (
		<div className={styles.asideMenu}>
			<div style={{height: 150}}>
				<img 
					className={styles.logoAsideBar} 
					src={Logo} 
					onLoad={() => setIsLoaded(true)}
					style={{ filter: !isLoaded ? "blur(10px)" : "none", transition: "filter 0.3s ease" }}
					alt="Logo"
				/>
			</div>

			<Divider sx={{ bgcolor: "white", my: 2, width: "100%" }} />

			<aside className={styles.asideMenuList}>
				<div className={styles.menuList}>
					<AsideMenuListAdmin open={props.open} />
					<Divider sx={{ bgcolor: "white", my: 2 }} />
				</div>
			</aside>
		</div>
	);
}

export default AsideMenuAdmin;
