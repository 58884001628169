export const captalizeFirstLetterEacheWord = (phrase) => {
	if(phrase === null || phrase === undefined || phrase === "")
		return "";
	const phraseParts = phrase.split(" ");
	let captalizedPhrase = "";
	for(let i = 0; i < phraseParts.length; i++){
		const word = phraseParts[i];
		if(word.length > 0){
			const captalizedWord = word[0].toUpperCase() + word.substring(1).toLowerCase();
			captalizedPhrase += captalizedWord;
		}
		if(Number(i) !== Number(phraseParts.length - 1))
			captalizedPhrase += " ";
	}
	return captalizedPhrase;
};

export const getDateWithoutTimezone = (date) => {
	let dateWithoutTimezone = new Date(date);
	var userTimezoneOffset = dateWithoutTimezone.getTimezoneOffset() * 60000;
	dateWithoutTimezone = new Date(dateWithoutTimezone.getTime() + userTimezoneOffset);
	return dateWithoutTimezone;
};

export function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) 
			return order;

		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

export function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

export function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) 
		return -1;

	if (b[orderBy] > a[orderBy]) 
		return 1;

	return 0;
}