import { CultivationRepository } from "../repository/cultivation_repository";

export class CultivationService {

	constructor() {
		this.cultivationRepository = new CultivationRepository();
	}

	async get(params) {
		const cultivationData = await this.cultivationRepository.get(params);
		return cultivationData;
	}

	async getByProperty(propertyId) {
		const cultivationData = await this.cultivationRepository.getByProperty(propertyId);
		return cultivationData.cultivations;
	}

	async getById(id) {
		const cultivation = await this.cultivationRepository.getById(id);
		return cultivation;
	}

	async save(cultivation) {
		const savedCultivation = await this.cultivationRepository.save(cultivation);
		return savedCultivation;
	}

	async update(cultivationId, cultivation) {
		const savedCultivation = await this.cultivationRepository.update(cultivationId, cultivation);
		return savedCultivation;
	}

	async changeVisibility(id) {
		await this.cultivationRepository.changeVisibility(id);
	}
}