import React, { useEffect, useState } from "react";
import InputSearch from "../../../components/SearchHeader/inputSeach";
import TopMenuAdmin from "../../../components/TopMenuAdmin";

//import css styles
import "../../CommonStyle/style.css";

//import components
import Search from "../../../components/SearchHeader";
import Table from "../../../components/TableGenerator";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Loading from "../../../components/Loading";
import { useParams } from "react-router-dom";
import { OccurrencesService } from "../../../service/occurrences_service";
import { getComparator, stableSort } from "../../../utils/utils";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//column
const columns = [
	{
		id:"nome",
		label: "Nome",
	},
	{
		id: "created",
		label: "Data de criação",
	}
];

function AdminIncidentType() {
	const { categoryId } = useParams();

	const [search, setSearch] = useState("");
	const [types, setTypes] = useState([]);
	const [allTypes, setAllTypes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const incidentService = new OccurrencesService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const params = {
			filterValue: encodeURI(JSON.stringify(
				{
					categoriaOcorrenciaId: categoryId
				}
			))
		};
		let typesInCategory = await incidentService.getAllTypes(params);

		typesInCategory = typesInCategory.map((type) => {
			
			return {
				id: type.id,
				nome: type.nome,
				created: new Date(type.createdAt).toLocaleDateString("pt-BR")
			};
		});
		setAllTypes(typesInCategory);
		setIsLoading(false);
		setTypes(
			stableSort(allTypes, getComparator("asc", columns[0].id))
				.slice(0,7));
	};

	const updateData = async (page, rowsPerPage, orderBy, order) => {
		setTypes(stableSort(allTypes, getComparator(order, orderBy)).slice(
			page * rowsPerPage,
			page * rowsPerPage + rowsPerPage
		));
	};

	const dataFilter = types.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);

	function handleClick(e) {
		setSearch(e.target.value);
	}

	const handleDelete = async (id) => {
		await incidentService.changeTypeVisibility(id);
		initData();
	};

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenuAdmin open={["admin", "incidents"]} />
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Search
										title="Tipos de ocorrências"
										href={`/admin/ocorrencias/${categoryId}/tipos/cadastro`}
										textButton="Novo Tipo"
									>
										<InputSearch func={handleClick} />
									</Search>
								</Item>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
										p: 0,
										m: 0,
									}}
								>
									<Table
										listSize={allTypes.length}
										data={dataFilter}
										columns={columns}
										handleDelete={handleDelete}
										editHref={"tipos/cadastro?id="}
										updateData={updateData}
									/>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}

export default AdminIncidentType;
