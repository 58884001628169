import { GroupServiceRepository } from "../repository/group_service_repository";

export class GroupServiceService {

	constructor() {
		this.groupServiceRepository = new GroupServiceRepository();
	}

	async get() {
		return await this.groupServiceRepository.get();
	}

	async getServices() {
		return await this.groupServiceRepository.getServices();
	}

	async getServiceByGroup(groupId) {
		return await this.groupServiceRepository.getServiceByGroup(groupId);
	}

	async save(groupService) {
		const savedGroupService = await this.groupServiceRepository.save(groupService);
		return savedGroupService;
	}

	async update(groupService) {
		const savedGroupService = await this.groupServiceRepository.update(groupService);
		return savedGroupService;
	}

	async getById(id) {
		const cultivation = await this.groupServiceRepository.getById(id);
		return cultivation;
	}

	async saveService(groupId, service) {
		const savedService = await this.groupServiceRepository.saveService(groupId, service);
		return savedService;
	}

	async updateService(groupId, service) {
		const savedService = await this.groupServiceRepository.updateService(groupId, service);
		return savedService;
	}

	async getServiceById(id) {
		const service = await this.groupServiceRepository.getServiceById(id);
		return service;
	}

	async changeVisibility(id) {
		await this.groupServiceRepository.changeVisibility(id);
	}

	async changeServiceVisibility(id) {
		await this.groupServiceRepository.changeServiceVisibility(id);
	}
}